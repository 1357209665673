import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Hidden, Typography } from '@mui/material';
import axios from 'axios';
import { API } from '../../utils/Api';
import Images from '../../utils/Images';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { CelebrationOutlined } from '@mui/icons-material';

const CouponsScreen = () => {
  const [joiningCoupons, setJoiningCoupons] = useState([]);
  const [validCoupons, setValidCoupons] = useState([]);
  const [applicableCoupons, setApplicableCoupons] = useState([]);
  const [birthdayCoupons, setBirthdayCoupons] = useState([]);
  const [referralCoupon, setReferralCoupon] = useState([]);
  const token = localStorage.getItem("token");
  const location = useLocation();
  const cartAmount = location.state.cartAmount;
  const navigate = useNavigate();
  const [outletData, setOutletData] = useState(JSON.parse(localStorage.getItem('selectedStoreData')));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        const response = await axios.get(`${API.BASE_URL}coupons/getUserApplicableCoupons/${outletData._id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const { birthdayCoupon, joiningCoupon, validCoupons, applicableCoupons, referralCoupon } = response.data.data.totalCoupons;
        setBirthdayCoupons(birthdayCoupon);
        setJoiningCoupons(joiningCoupon);
        setValidCoupons(validCoupons);
        setReferralCoupon(referralCoupon);
        setApplicableCoupons(applicableCoupons);
        console.log(response.data.data);
      } catch (error) {
        console.error('Error fetching coupons:', error);
      }
    };
    fetchCoupons();
  }, []);

  const applyCoupon = async (couponId) => {
    try {
      const response = await axios.post(`${API.BASE_URL}coupons/applyCoupon`, { couponId }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Coupon applied successfully:', response.data);
      // setRefreshTrigger(!refreshTrigger);
      toast.success("Coupon applied successfully!!!");
      // navigate('/cartScreen');
      navigate('/cartScreen', { state: { couponApplied: true } });
    } catch (error) {
      console.error('Error applying coupon:', error);
      toast.error(error.response.data.error);
    }
  };

  const removeCoupon = async (couponId) => {
    try {
      const response = await axios.post(`${API.BASE_URL}coupons/removeCoupon`, { couponId }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Coupon removed successfully:', response.data);
      // setRefreshTrigger(!refreshTrigger);
    } catch (error) {
      console.error('Error removing coupon:', error);
    }
  };

  return (
    <>
    <Box className="couponsScreen">
      <Hidden mdUp>
        <Box className="coupons-title">
          <div className='couponHeading'>
            <Typography className='headingText'>APPLY COUPON</Typography>
          </div>
          <Typography className='headingCart'>Your Cart: ₹{cartAmount}</Typography>
        </Box>
      </Hidden>
      <Box className="couponContainer">
        {birthdayCoupons?.length > 0 && (
          <>
            <Typography className='bestTitle'>Here's your birthday gift from us <CelebrationOutlined /></Typography>
            {birthdayCoupons?.map(coupon => (
              <Box className="coupon birthdayCoupon">
                <Grid container>
                  <Grid item xs={10} className='couponDetailSection'>
                    <div className='couponDetail'>
                      <Typography className='couponName'>{coupon.couponCode}</Typography>
                      {coupon?.discountPercent && !coupon?.discountAmount && (
                        <Typography className='couponOff'>Save {coupon?.discountPercent}% with this coupon</Typography>
                      )}
                      {coupon?.discountAmount && (
                        <Typography className='couponOff'>Save ₹{coupon?.discountAmount} with this coupon</Typography>
                      )}
                      {coupon?.sizeUpgradeAddon && (
                        <Typography className='couponOff'>A free size upgrade on drinks!!!</Typography>
                      )}
                      {coupon?.isBogoCoupon && (
                        <Typography className='couponOff'>Buy one get one free coupon!!!</Typography>
                      )}
                      <Typography className='couponDesc'>{coupon.description}</Typography>
                      <Box className="validAndButton" sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                        {coupon.validityEndDate ? (
                          <Typography className="validity" sx={{ marginRight: 'auto' }}>
                            Valid till: {new Date(coupon.validityEndDate).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' }).replace(/\//g, '-')}
                          </Typography>
                        ) : (
                          <span style={{ flex: 1 }}></span>
                        )}
                        <Button className='applyButton' onClick={() => applyCoupon(coupon._id)}>Apply</Button>
                      </Box>
                    </div>
                  </Grid>
                  <Grid item xs={2} className='couponDiscountSection'>
                    {coupon?.discountPercent && !coupon?.discountAmount && (
                      <Typography className='verticalFlatOff'>{coupon?.discountPercent}% OFF</Typography> 
                    )}
                    {coupon?.discountAmount && (
                      <Typography className='verticalFlatOff'>₹{coupon?.discountAmount} OFF</Typography> 
                    )}
                    {coupon?.sizeUpgradeAddon && (
                      <Typography className='verticalFlatOff'>FREE!!!</Typography>
                    )}
                    {coupon?.isBogoCoupon && (
                      <Typography className='verticalFlatOff'>FREE!!!</Typography>
                    )} 
                    {coupon?.discountPrice && (
                      <Typography className='verticalFlatOff'>{`Only ₹${coupon?.discountPrice}` || `FREE!!!`}</Typography>
                    )}
                  </Grid>
                </Grid>
              </Box>
            ))}
          </>
        )}
        {/* <Typography className='bestTitle'>Joining Coupons</Typography> */}
        {joiningCoupons?.map(coupon => (
          <Box className="coupon">
              <Grid container>
                <Grid item xs={10} className='couponDetailSection'>
                  <div className='couponDetail'>
                    <Typography className='couponName'>{coupon.couponCode}</Typography>
                    {coupon?.discountPercent && !coupon?.discountAmount && (
                      <Typography className='couponOff'>Save {coupon?.discountPercent}% with this coupon</Typography>
                    )}
                    {coupon?.discountAmount && (
                      <Typography className='couponOff'>Save ₹{coupon?.discountAmount} with this coupon</Typography>
                    )}
                    {coupon?.sizeUpgradeAddon && (
                      <Typography className='couponOff'>A free size upgrade on drinks!!!</Typography>
                    )}
                    {coupon?.isBogoCoupon && (
                      <Typography className='couponOff'>Buy one get one free coupon!!!</Typography>
                    )}
                    <Typography className='couponDesc'>{coupon.description}</Typography>
                    <Box className="validAndButton" sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                      {coupon.validityEndDate ? (
                        <Typography className="validity" sx={{ marginRight: 'auto' }}>
                          Valid till: {new Date(coupon.validityEndDate).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' }).replace(/\//g, '-')}
                        </Typography>
                      ) : (
                        <span style={{ flex: 1 }}></span>
                      )}
                      <Button className='applyButton' onClick={() => applyCoupon(coupon._id)}>Apply</Button>
                    </Box>
                  </div>
                </Grid>
                <Grid item xs={2} className='couponDiscountSection'>
                  {coupon?.discountPercent && !coupon?.discountAmount && (
                    <Typography className='verticalFlatOff'>{coupon?.discountPercent}% OFF</Typography> 
                  )}
                  {coupon?.discountAmount && (
                    <Typography className='verticalFlatOff'>₹{coupon?.discountAmount} OFF</Typography> 
                  )}
                  {coupon?.sizeUpgradeAddon && (
                    <Typography className='verticalFlatOff'>FREE!!!</Typography>
                  )}
                  {coupon?.isBogoCoupon && (
                    <Typography className='verticalFlatOff'>FREE!!!</Typography>
                  )} 
                  {coupon?.discountPrice && (
                    <Typography className='verticalFlatOff'>{`Only ₹${coupon?.discountPrice}` || `FREE!!!`}</Typography>
                  )}
                </Grid>
              </Grid>
          </Box>
        ))}
        {referralCoupon?.map(coupon => (
          <Box className="coupon">
            <Grid container>
              <Grid item xs={10} className='couponDetailSection'>
                <div className='couponDetail'>
                  <Typography className='couponName'>{coupon.couponCode}</Typography>
                  {coupon?.discountPercent && !coupon?.discountAmount && (
                    <Typography className='couponOff'>Save {coupon?.discountPercent}% with this coupon</Typography>
                  )}
                  {coupon?.discountAmount && (
                    <Typography className='couponOff'>Save ₹{coupon?.discountAmount} with this coupon</Typography>
                  )}
                  {coupon?.sizeUpgradeAddon && (
                    <Typography className='couponOff'>A free size upgrade on drinks!!!</Typography>
                  )}
                  {coupon?.isBogoCoupon && (
                    <Typography className='couponOff'>Buy one get one free coupon!!!</Typography>
                  )}
                  <Typography className='couponDesc'>{coupon.description}</Typography>
                  <Box className="validAndButton" sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                    {coupon.validityEndDate ? (
                      <Typography className="validity" sx={{ marginRight: 'auto' }}>
                        Valid till: {new Date(coupon.validityEndDate).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' }).replace(/\//g, '-')}
                      </Typography>
                    ) : (
                      <span style={{ flex: 1 }}></span>
                    )}
                    <Button className='applyButton' onClick={() => applyCoupon(coupon._id)}>Apply</Button>
                  </Box>
                </div>
              </Grid>
              <Grid item xs={2} className='couponDiscountSection'>
                {coupon?.discountPercent && !coupon?.discountAmount && (
                  <Typography className='verticalFlatOff'>{coupon?.discountPercent}% OFF</Typography> 
                )}
                {coupon?.discountAmount && (
                  <Typography className='verticalFlatOff'>₹{coupon?.discountAmount} OFF</Typography> 
                )}
                {coupon?.sizeUpgradeAddon && (
                  <Typography className='verticalFlatOff'>FREE!!!</Typography>
                )}
                {coupon?.isBogoCoupon && (
                  <Typography className='verticalFlatOff'>FREE!!!</Typography>
                )} 
                {coupon?.discountPrice && (
                  <Typography className='verticalFlatOff'>{`Only ₹${coupon?.discountPrice}` || `FREE!!!`}</Typography>
                )}
              </Grid>
            </Grid>
          </Box>
        ))}
        {/* <Typography className='bestTitle'>Applicable Coupons</Typography> */}
        {applicableCoupons?.map(coupon => (
          <Box className="coupon">
              <Grid container>
                  <Grid item xs={10} className='couponDetailSection'>
                    <div className='couponDetail'>
                      <Typography className='couponName'>{coupon.couponCode}</Typography>
                      {/* <Typography className='couponOff'>Save {coupon.discountPercent}% with this coupon</Typography> */}
                      {coupon?.discountPercent && !coupon?.discountAmount && (
                        <Typography className='couponOff'>Save {coupon?.discountPercent}% with this coupon</Typography>
                      )}
                      {coupon?.discountAmount && (
                        <Typography className='couponOff'>Save ₹{coupon?.discountAmount} with this coupon</Typography>
                      )}
                      {coupon?.sizeUpgradeAddon && (
                        <Typography className='couponOff'>A free size upgrade on drinks!!!</Typography>
                      )}
                      {coupon?.isBogoCoupon && (
                        <Typography className='couponOff'>Buy one get one free coupon!!!</Typography>
                      )}
                      <Typography className='couponDesc'>{coupon.description}</Typography>
                      <Box className="validAndButton" sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                        {coupon.validityEndDate ? (
                          <Typography className="validity" sx={{ marginRight: 'auto' }}>
                            Valid till: {new Date(coupon.validityEndDate).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' }).replace(/\//g, '-')}
                          </Typography>
                        ) : (
                          <span style={{ flex: 1 }}></span>
                        )}
                        <Button className='applyButton' onClick={() => applyCoupon(coupon._id)}>Apply</Button>
                      </Box>
                    </div>
                  </Grid>
                  <Grid item xs={2} className='couponDiscountSection'>
                    {coupon?.discountPercent && !coupon?.discountAmount && (
                      <Typography className='verticalFlatOff'>{coupon?.discountPercent}% OFF</Typography> 
                    )}
                    {coupon?.discountAmount && (
                      <Typography className='verticalFlatOff'>₹{coupon?.discountAmount} OFF</Typography> 
                    )}
                    {coupon?.sizeUpgradeAddon && (
                      <Typography className='verticalFlatOff'>FREE!!!</Typography>
                    )}
                    {coupon?.isBogoCoupon && (
                      <Typography className='verticalFlatOff'>FREE!!!</Typography>
                    )}
                    {coupon?.discountPrice && (
                      <Typography className='verticalFlatOff'>{`Only ₹${coupon?.discountPrice}` || `FREE!!!`}</Typography>
                    )}
                  </Grid>
              </Grid>
          </Box>
        ))}
        {validCoupons?.length > 0 && (
          <>
            <Typography className='bestTitle'>Other available offers</Typography>
            {validCoupons?.map(coupon => (
              <Box className="coupon notApplicable">
                <Grid container>
                  <Grid item xs={10} className='couponDetailSection'>
                    <div className='couponDetail'>
                      <Typography className='couponTag'>{coupon.couponMessage}</Typography>
                      <Typography className='couponName'>{coupon.couponCode}</Typography>
                      {coupon?.discountPercent && !coupon?.discountAmount && (
                        <Typography className='couponOff'>Save {coupon?.discountPercent}% with this coupon</Typography>
                      )}
                      {coupon?.discountAmount && (
                        <Typography className='couponOff'>Save ₹{coupon?.discountAmount} with this coupon</Typography>
                      )}
                      {coupon?.sizeUpgradeAddon && (
                        <Typography className='couponOff'>A free size upgrade on drinks!!!</Typography>
                      )}
                      {coupon?.isBogoCoupon && (
                        <Typography className='couponOff'>Buy one get one free coupon!!!</Typography>
                      )}
                      <Typography className='couponDesc'>{coupon.description}</Typography>
                      <Box className="validAndButton">
                        {coupon.validityEndDate && (
                          <Typography className="validity">
                            Valid till: {new Date(coupon.validityEndDate).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' }).replace(/\//g, '-')}
                          </Typography>
                        )}
                      </Box>
                    </div>
                  </Grid>
                  <Grid item xs={2} className='couponDiscountSection'>
                    {coupon?.discountPercent && !coupon?.discountAmount && (
                      <Typography className='verticalFlatOff'>{coupon?.discountPercent}% OFF</Typography> 
                    )}
                    {coupon?.discountAmount && (
                      <Typography className='verticalFlatOff'>₹{coupon?.discountAmount} OFF</Typography> 
                    )}
                    {coupon?.sizeUpgradeAddon && (
                      <Typography className='verticalFlatOff'>FREE!!!</Typography>
                    )}
                    {coupon?.isBogoCoupon && (
                      <Typography className='verticalFlatOff'>FREE!!!</Typography>
                    )} 
                    {coupon?.discountPrice && (
                      <Typography className='verticalFlatOff'>{`Only ₹${coupon?.discountPrice}` || `FREE!!!`}</Typography>
                    )}
                  </Grid>
                </Grid>
              </Box>
            ))}
          </>
        )}
      </Box>
    </Box>
    </>
  );
};

export default CouponsScreen;