import React, { useEffect, useState } from 'react';
import './App.css';
import './index.css';
import './sass/main.scss';
import AppRoutes from './AppRoutes';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Toaster } from 'react-hot-toast';
import { SearchProvider } from './components/SearchBar';
import { ThemeProvider, createTheme } from '@mui/material';

const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
});

function App() {

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
      <BrowserRouter>
        <SearchProvider>
          <AppRoutes />
        </SearchProvider>
      </BrowserRouter>
      <Toaster position="bottom-center" toastOptions={{
          success: {
            duration: 3000,
            style: {
              borderRadius: '30px',
              backgroundColor: '#1E9CED',
              color: '#fff'
            },
          },
          error: {
            duration: 3000,
            style: {
              backgroundColor: '#E34234',              
              borderRadius: '30px',
              color: '#fff'
            },
          },
        }} 
        reverseOrder={true} 
      />
      </ThemeProvider>
      {/* <ToastContainer position="bottom-center" autoClose={3000} theme="colored" hideProgressBar={true} pauseOnHover={false} /> */}
    </div>
  );
}

export default App;