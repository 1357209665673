import { Box, Typography, Modal, TextField, Grid, InputAdornment, CardMedia, Card, CardContent, Button, Autocomplete, FormControl, RadioGroup, FormControlLabel, Radio, Drawer } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Images from '../utils/Images';
import { SearchBar } from './SearchBar';
import { KeyboardArrowDown, KeyboardArrowRight, SearchSharp } from '@mui/icons-material';
import StoreIcon from '@mui/icons-material/Store';
import { API } from '../utils/Api';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { setUser } from '../../src/store/Profile/UserSlice';
import * as Yup from 'yup';
import PrimaryButton from './PrimaryButton';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import ProfileScreen from '../screens/App/ProfileScreen';
import { getPreciseDistance } from 'geolib';
import AdvertiseScreen from '../screens/App/AdvertiseScreen';

const DesktopHeader = ({ }) => {
  const [store, setStore] = useState([]);
  const [open, setopen] = useState(false);
  const [openAccount, setopenAccount] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [number, setNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [profileImage, setProfileImage] = useState('');
  const [countdown, setCountdown] = useState(59);
  const [errors, setErrors] = useState({});
  const [showOtpField, setShowOtpField] = useState(false);
  const [isGetOtpButtonClicked, setIsGetOtpButtonClicked] = useState(false);
  const [changeNumberClicked, setChangeNumberClicked] = useState(false); 
  const [openLocation, setopenLocation] = useState(!JSON.parse(localStorage.getItem('selectedStoreData')));
  const [authType, setAuthType] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [map, setMap] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [cities, setCities] = useState([]);
  const [pincode, setPincode] = useState('');
  const [selectedCity, setSelectedCity] = useState("");
  const [storesData, setStoresData] = useState([]);
  const [userData, setUserData] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [couponModalOpen, setCouponModalOpen] = useState(false);
  const [noOutlets, setNoOutlets] = useState(false);
  const [selectedOrderType, setSelectedOrderType] = useState('');
  const [selectedStore, setSelectedStore] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const initialDrawerState = !localStorage.getItem('selectedOrderType');
  // const [drawerOpenForType, setDrawerOpenForType] = useState(true);
  const [drawerOpenForType, setDrawerOpenForType] = useState(initialDrawerState);
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [detectingLocation, setDetectingLocation] = useState(false);
  const [couponPercentageModal, setCouponPercentageModal] = useState('');
  const [couponCodeModal, setCouponCodeModal] = useState('');
  const params = useParams();
  const referralId = params['referId'];


  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  // const toggleDrawerForSelectingType = (open) => (event) => {
  //   if (
  //     event.type === 'keydown' &&
  //     (event.key === 'Tab' || event.key === 'Shift')
  //   ) {
  //     return;
  //   }
  //   setDrawerOpenForType(open);
  // };

  const toggleDrawerForSelectingType = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    const selectedOrderType = localStorage.getItem('selectedOrderType');
    if (!selectedOrderType && !open) {
      return;
    }
    setDrawerOpenForType(open);
  };

  const handleOpenLocation = () => {
    setopenLocation(true);
  }

  const handleCloseLocation = () => {
    if (selectedStore === null) {
      console.log("Cannot close modal, no store selected.");
      return;
    }
    const selectedOrderType = localStorage.getItem('selectedOrderType');
    if (!selectedOrderType) {
      console.log("Cannot close modal, order type not selected.");
      return;
    }
    setopenLocation(false);
  };

  const handleCitySelection = (city) => {
    setSelectedCity(city);
  };

  const center = {
    lat: currentLocation?.lat || 0,
    lng: currentLocation?.lng || 0,
  };

  const onLoad = (map) => {
    setMap(map);
  };

  useEffect(() => {
    // console.log(token);
    setUserData(JSON.parse(localStorage.getItem('userDataLogin')));
    // localStorage.clear();
    const fetchStoreData = async () => {
      try {
        const response = await axios.get(`${API.BASE_URL}outlet/city/distinct`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.status === true) {
          const citiesData = response?.data?.data?.outlets?.map((order) => order.city);
          setCities(citiesData);
          console.log(cities);
        } else {
          console.error("API request failed");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchStoreData();
  }, []);

  const fetchLocation = async () => {
    setStoresData([]);
    setDetectingLocation(true);
    try {
        const position = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
        });
        const { latitude, longitude } = position.coords;
        setLatitude(latitude);
        setLongitude(longitude);
        console.log(latitude, longitude);
        const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
        // const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=15.5993424&lon=73.800734`;
        const response = await fetch(url);
        const data = await response.json();
        if (data.address) {
            const city = data?.address?.city;
            const pincode = data?.address?.postcode;
            setPincode(data?.address?.postcode)
            console.log('Location detecting', city, pincode);
            setDetectingLocation(true);
            if (city && pincode) {
                try {
                    const additionalResponse = await axios.get(
                        `${API.BASE_URL}outlet/getOutletCityPincode?city=${city}&pincode=${pincode}`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    console.log("City response:", additionalResponse.data);
                    if (additionalResponse?.data?.status === false) {
                      setNoOutlets(true);
                    } else {
                      setStoresData(additionalResponse?.data?.data?.totalOutletRecords);
                      setNoOutlets(false);
                    }
                } catch (error) {
                    console.log("Error fetching additional data:", error);
                }
            }
        } else {
          console.error("Geocoding API did not return expected data");
          setDetectingLocation(false);
        }
    } catch (error) {
      console.error("Error getting current location:", error);
    } finally {
      setLoading(false);
      setDetectingLocation(false);
    }
  };

  const changeCity = async () => {
    setStoresData([]);
    try {
      const additionalResponse = await axios.get(
        `${API.BASE_URL}outlet/getOutletCityPincode?city=${selectedCity}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Selected city Response:", additionalResponse.data);
      if (additionalResponse.data.status === false) {
        setNoOutlets(true);
      } else {
        const selectedCityData = additionalResponse.data.data;
        const selectedStoreData = selectedCityData.outlets;
        setStoresData(selectedStoreData);
        setNoOutlets(false);
      }
    } catch (error) {
      console.log("Change city error data:", error);
    }
  };

  useEffect(() => {
    const changeCity = async () => {
      try {
        const additionalResponse = await axios.get(
          `${API.BASE_URL}outlet/getOutletCityPincode?city=${selectedCity}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("Selected city Response:", additionalResponse?.data);
        if (additionalResponse?.data?.status === false) {
          setNoOutlets(true);
        } else {
          setNoOutlets(false);
          setStoresData(additionalResponse?.data?.data?.totalOutletRecords);
        }
      } catch (error) {
        console.log("Change city error data:", error);
      }
    };
    if (selectedCity) {
      changeCity();
    }
  }, [selectedCity]);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleStoreClick = (selectedStore) => {
    localStorage.setItem('selectedStoreData', JSON.stringify(selectedStore));
    setStore(JSON.parse(localStorage.getItem('selectedStoreData')));
    // handleCloseLocation();
    // setStoresData([]);
    setSelectedStore(selectedStore);
  };

  const handleOpen = () => {
    if (!token) {
      setopen(true);
    }
  };

  const handleClose = () => {
    setopen(false);
  }
  
  const handleOpenAccount = ()=> {
    setopenAccount(true);
  }

  const handleCloseAccount = () => {
    setopenAccount(false);
  }

  useEffect(() => {
    let interval;
    if (isGetOtpButtonClicked && countdown > 0) {
      interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [countdown, isGetOtpButtonClicked]);

  const schema = Yup.object().shape({
    number: Yup.string().matches(/^[6789][0-9]{9}$/, 'Must be a 10 digit number starting with 6, 7, 8, or 9').required('Mobile Number is required'),
  });

  const validateField = async (field, value) => {
    try {
      await schema.validateAt(field, { number: value });
      setErrors({ ...errors, [field]: '' });
      if (field === 'number' && value.length === 10) {
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        setErrors({ ...errors, [field]: error.message });
      }
    }
  };

  const handleResendOtp = async () => {
    try {
      const response = await axios.post(`${API.BASE_URL}auth/user/send-otp`, {
        "mobile": number,
      });
      if (response.data.status === true) {
        toast.success('OTP resent successfully');
      }
    } catch (error) {
      console.error('Error:', error.response.data.data.message);
      let errorMessage = error.response.data.data.message;
      toast.error(errorMessage);
    } finally {
    }
  };

  const handleInputChange = (field, value) => {
    if (field === 'number' && !isGetOtpButtonClicked) {
      setNumber(value);
      validateField('number', value);
    } else if (field === 'otp') {
      setOtp(value);
      setErrors({ ...errors, [field]: '' });
    }
    //  else if (field === 'name') {
    //   setName(value);
    //   setErrors({ ...errors, name: '' });
    //   validateField('name', value);
    // } else if (field === 'email') {
    //   setEmail(value);
    //   validateField('email', value);
    //   setErrors({ ...errors, email: '' });
    // }
  };

  const handleButtonClick = async () => {
    const isValid = await schema.isValid({ number });
    if (!isValid) {
      setErrors({ ...errors, number: 'Mobile Number is required' });
      return;
    }
    setIsGetOtpButtonClicked(true);
    setBtnDisabled(true);
    try {
      const response = await axios.post(`${API.BASE_URL}auth/user/send-otp`, {
        "mobile": number,
      });
      if (response.data.status === true) {
        console.log('OTP sent successfully:', response.data);
        toast.success('OTP sent successfully!');
        setShowOtpField(true);
        setCountdown(59);
      }
      setAuthType(response.data.data.authType);
      setBtnDisabled(false);
    } catch (error) {
      console.error('Error:', error.response.data.data?.message);
      let errorMessage = error.response.data.data.message;
      toast.error(errorMessage);
      setBtnDisabled(false);
    }
  };

  const handleOtpButtonClick = () => {
    if (authType === 'LOGIN') {
      setBtnDisabled(true);
      axios.post(`${API.BASE_URL}auth/user/verify-otp`, {
        "mobile": number,
        "otp": otp,
        "isSignup": false
      })
      .then(response => {
        console.log('Login API Response:', response.data);
        localStorage.setItem('userNameHeader', response.data.data.user.name);
        // localStorage.setItem('userProfileImage', response.data.data.user.profileImage);
        if (response.data.data.user.profileImage !== null) {
          localStorage.setItem('userProfileImage', response.data.data.user.profileImage);
        }
        localStorage.setItem('token', response.data.data.token);
        localStorage.setItem('userDataLogin', JSON.stringify(response.data.data.user));
        dispatch(setUser(response.data.data.user));
        toast.success('Logged in successfully!');
        setBtnDisabled(false);
        handleClose();
        window.location.reload();
      })
      .catch(error => {
        console.error('Error:', error.response.data.data.message);
        let errorMessage = error.response.data.data.message;
        toast.error(errorMessage);
        setBtnDisabled(false);
      });
    }
    else {
      setBtnDisabled(true);
      axios.post(`${API.BASE_URL}auth/user/verify-otp`, {
        "mobile": number,
        "otp": otp,
        "isSignup": true
      })
      .then(response => {
        console.log('Signin API Response:', response.data);
        toast.success('OTP verified successfully!');
        setBtnDisabled(false);
        handleClose();
        handleOpenAccount();
      })
      .catch(error => {
        console.error('Error:', error.response.data.data.message);
        let errorMessage = error.response.data.data.message;
        toast.error(errorMessage);
        setBtnDisabled(false);
      });
    }
  };

  const handleChangeNumberClick = () => {
    setChangeNumberClicked(true);
    setShowOtpField(false);
    setIsGetOtpButtonClicked(false);
    setCountdown(0);
  };

  const accountSchema = Yup.object().shape({
    name: Yup.string().required('Name is required').matches(/^[A-Za-z][A-Za-z'-]* ?([A-Za-z'-]+ ?)*$/, 'Name must contain only letters, spaces, hyphens, and apostrophes').min(2, 'Name must be at least 2 characters').trim(),
    // name: Yup.string().required('Name is required').matches(/^[A-Za-z]+$/, 'Name must contain only letters').min(2, 'Name must be at least 2 characters').trim(),
    email: Yup.string().email('Invalid email address').required('Email is required').trim(),
  });

  const validateAccountFields = async (field, value) => {
    try {
      await schema.validateAt(field, { [field]: value });
      setErrors({ ...errors, [field]: '' });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        setErrors({ ...errors, [field]: error.message });
      }
    }
  };

  const handleInputAccountChange = (field, value) => {
    if (field === 'name') {
      setName(value);
      validateField('name', value);
    } else if (field === 'email') {
      setEmail(value);
      validateField('email', value);
    }
  };

  const handleAccountButtonClick = async () => {
    try {
      await accountSchema.validate({ name, email }, { abortEarly: false });
      const requestData = {
        name: name,
        signupType: "REGULAR",
        mobile: number,
      };
      if (email) {
        requestData.email = email;
      }
      if (referralId) {
        requestData.referringUser = referralId;
      }
      const response = await axios.post(
        `${API.BASE_URL}auth/user/create-account`,
        requestData
      );
      if (response.data.status === true) {
        console.log("Account created successfully:", response?.data?.data);
        toast.success("Account created successfully!", { autoClose: 3000 });
        handleCloseAccount();
        localStorage.setItem("token", response?.data?.data?.token);
        localStorage.setItem("couponPercentage", response?.data?.data?.userCouponData?.coupon?.discountPercent);
        setCouponPercentageModal(response?.data?.data?.userCouponData?.coupon?.discountPercent);
        localStorage.setItem("couponCode", response?.data?.data?.userCouponData?.coupon?.couponCode);
        setCouponCodeModal(response?.data?.data?.userCouponData?.coupon?.couponCode);
        localStorage.setItem('userNameHeader', response?.data?.data?.createdUser?.name);
        // if (response?.data?.data?.user?.profileImage !== null) {
        //   localStorage.setItem('userProfileImage', response?.data?.data?.user?.profileImage);
        // }
        localStorage.setItem('userProfileImage', Images.navProfile);
        // localStorage.setItem('userProfileImage', response.data.data.user.profileImage);
        localStorage.setItem('userDataLogin', JSON.stringify(response?.data?.data?.createdUser));
        setCouponModalOpen(true);
      }
    } catch (validationError) {
      if (validationError instanceof Yup.ValidationError) {
        const newErrors = {};
        validationError.inner.forEach((error) => {
          newErrors[error.path] = error?.message;
        });
        setErrors(newErrors);
      } else {
        console.error("Error:", validationError?.response);
        let errorMessage = validationError?.response?.data?.data?.message || validationError?.response?.data?.error;
        toast.error(errorMessage);
      }
    }
  };

  const handleCouponModalClose = () => {
    setCouponModalOpen(false);
    window.location.reload();
  };

  useEffect(() => {
    setStore(JSON.parse(localStorage.getItem('selectedStoreData')));
    console.log('Store data', store);
  }, [])

  useEffect(() => {  
    const storedUserName = localStorage.getItem('userNameHeader');
    const storedProfileImage = localStorage.getItem('userProfileImage');
    if (storedUserName) {
      setName(storedUserName);
      setProfileImage(storedProfileImage);
    }
  }, [handleOtpButtonClick]);

  const handleChange = (event) => {
    const selectedOrderType = event.target.value;
    setSelectedOrderType(selectedOrderType);
    localStorage.setItem('selectedOrderType', selectedOrderType);
    console.log('Selected Value:', selectedOrderType);
    setopenLocation(false);
  };
  
  const calculateDistance = (store) => {
    const lat = store?.coordinates?.latitude;
    const lon = store?.coordinates?.longitude;
    if (lat == null || lon == null || isNaN(parseFloat(lat)) || isNaN(parseFloat(lon))) {
      return null;
    }
    const distanceInMeters = getPreciseDistance(
      { latitude, longitude },
      { latitude: parseFloat(lat), longitude: parseFloat(lon) }
    );
    if (isNaN(distanceInMeters)) {
      return null;
    }
    if (distanceInMeters < 1000) {
      return `${distanceInMeters} meters`;
    } else {
      const distanceInKilometers = (distanceInMeters / 1000).toFixed(1);
      return `${distanceInKilometers} km`;
    }
  };

  const handleOptionSelect = (option) => {
    setSelectedOrderType(selectedOrderType);
    localStorage.setItem('selectedOrderType', option);
    console.log(option);
    handleCloseLocation();
    window.location.reload();
    setopenLocation(false);
  };

  return (
    <>
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-brand">
          <Box className="" onClick={() => navigate('/')} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <img className='navLogo' src={Images.xpanseNavLogo} alt="Logo" />
            <Box className="textBox">
              <Typography className="title">XPANSE</Typography>
              <Typography className="navTag">Freshly Brewed</Typography>
            </Box>
          </Box>
        </div>
        <div style={{ display: 'flex'}}>
          <Box onClick={() => setDrawerOpenForType(true)} className="storeHeader" sx={{ cursor: 'pointer'}}>
            <Box sx={{ display: 'flex', margin: '0px', padding: '0px' }}>
              <StoreIcon className='storeIcon' />
              <Typography className='storeCity'>{store?.name}</Typography>
              <KeyboardArrowRight />
            </Box>
            <Typography className="storeAddress2">{store?.address}</Typography>
          </Box>
          <SearchBar />
        </div>
        <div onClick={() => {
          const token = localStorage.getItem('token');
            if (token) {
              setDrawerOpen(true);
            } else {
              handleOpen();
            }
          }}>
            <Box className="loginBox" sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
              <Typography className="loginText" sx={{ width: '100%' }}>{userData?.name || 'Login / Sign Up'}</Typography>
              <img className="profileImage"
                src={profileImage ? profileImage : userData?.gender === 'MALE' ? Images.maleAvatar : userData?.gender === 'FEMALE' ? Images.femaleAvatar : Images.navProfile} 
                alt='profileIcon' 
                onError={(e) => { e.target.onerror = null; e.target.src = Images.navProfile; }}
              />
            </Box>
        </div>
      </div>
    </nav>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      sx={{ border: '0'}}
    >
      <Box className="authenticationScreen" sx={{outline: 'none'}}>
        <Grid className='mainBox' item md={3} sx={{ padding: '10px', margin: '0px' }}>
          <Box sx={{ textAlign: 'center' }}>
            <img className='imageLogo' src={Images.xpanseSplashLogo} alt="" />
          </Box>
          { !isGetOtpButtonClicked && 
            <Typography className="cup-text" sx={{ fontWeight: '600', fontSize: '25px', margin: '21px 0 31px 0', lineHeight: '1.2' }}>
              Lets Get You a Cup
            </Typography>
          }
          { isGetOtpButtonClicked &&
            <Box className="step-away-text" sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <ArrowBack sx={{ fontSize: '25px', margin: '0 8px 0 0px' }} onClick={handleChangeNumberClick} />
              <Typography sx={{ fontWeight: '600', fontSize: '25px' }}>
                Your cup is one step away
              </Typography>
            </Box>
          }
          <Box sx={{ textAlign: 'left' }}>
            <Box className="numberField">
              <TextField
                fullWidth
                id="number"
                sx={{
                  borderRadius: '8px',
                  backgroundColor: '#fff',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#1DA1F2',
                    },
                    '&:hover fieldset': {
                      borderColor: 'cornflowerblue',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#1DA1F2',
                    },
                  },
                }}
                disabled={isGetOtpButtonClicked}
                placeholder="10 digit mobile number"
                variant="outlined"
                value={number}
                onChange={(e) => handleInputChange('number', e.target.value)}
                onBlur={() => validateField('number', number)}
                InputLabelProps={{ style: { color: '#2196F3' } }}
                error={!!errors.number}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box sx={{ display: 'flex', borderRight: '1px solid #474747', paddingRight: '8px' }}>
                        <span>+91</span>
                      </Box>
                    </InputAdornment>
                  ),
                  style: { borderColor: '#2196F3' },
                }}
              />
              {errors.number && (
                <Typography className="errorText" color="error">
                  {errors.number}
                </Typography>
              )}
            </Box>
            <Box>
              <TextField
                fullWidth
                id="otp"
                className={`otpField ${showOtpField ? 'showOtpField' : ''}`}
                sx={{
                  borderRadius: '8px',
                  marginTop: '10px',
                  backgroundColor: '#fff',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#1DA1F2',
                    },
                    '&:hover fieldset': {
                      borderColor: 'cornflowerblue',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#1DA1F2',
                    },
                  },
                }}
                placeholder="Enter OTP"
                variant="outlined"
                value={otp}
                onChange={(e) => handleInputChange('otp', e.target.value)}
                onBlur={() => validateField('otp', otp)}
                inputProps={{ style: { textAlign: 'center' } }}
                InputLabelProps={{ style: { color: '#2196F3' } }}
                error={!!errors.otp}
              />
              {errors.otp && (
                <Typography className="errorText" color="error">
                  {errors.otp}
                </Typography>
              )}
            </Box>
          </Box>
          <Grid container justifyContent={'center'} style={{ margin: '27px 0 0px 0' }}>
            <Grid className="buttonContainer" item xs={12} sm={12} md={12} style={{ top: showOtpField ? '0px' : '-60px' }}>
              <PrimaryButton
                title={showOtpField ? 'Proceed' : 'Get OTP'}
                style={{ borderRadius: '30px', fontSize: '58px' }}
                onClick={() => {
                  if (!showOtpField) {
                    handleButtonClick();
                  } else {
                    handleOtpButtonClick();
                  }
                }}
                spinner={true}
                disabledTitle={'Please Wait'}
                disabled={btnDisabled}
              />
            </Grid>
          </Grid>
          { isGetOtpButtonClicked &&
            <Typography  className="resendOtp-text" style={{ fontSize: '16px', textAlign: 'center', margin: '20px 0 10px 0',  height: showOtpField ? '50px' : '0px'  }}>
              {countdown <= 0 ? (
                  <span
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => {
                      handleResendOtp();
                      setCountdown(59);
                    }}
                  >
                    Resend OTP
                  </span>
                  ) : (
                  <span>
                    Resend OTP in
                  <span
                  style={{
                    textDecoration: 'underline',
                    color: '#fff',
                    cursor: 'pointer',
                    marginLeft: '5px',
                  }}
                  >
                    {countdown < 10 ? `00:0${countdown}` : `00:${countdown}`}
                  </span>
                  </span>
              )}
            </Typography>
          }
          { !isGetOtpButtonClicked &&
            <Typography className='termsConditions' style={{ color: '#000', fontSize: '13px', textAlign: 'center' }}>
              By clicking, I accept the <span onClick={() => navigate('/termsAndConditions')} style={{ textDecoration: 'underline', color: '#fff', cursor: 'pointer' }}>terms of service</span> &{' '}
              <span onClick={() => navigate('/privacyPolicy')} style={{ textDecoration: 'underline', color: '#fff', cursor: 'pointer' }}>privacy policy</span>
            </Typography>
          }
        </Grid>
      </Box>
    </Modal>
    <Modal
      open={openAccount}
      onClose={handleCloseAccount}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      sx={{ border: '0'}}
    >
      <Box className="accountDetails" sx={{ outline: 'none', textAlign: 'left', padding: '0px', margin: '0px' }}>
        <Box sx={{ textAlign: 'center' }}>
          <img className='imageLogo' src={Images.xpanseSplashLogo} alt="" />
        </Box>
        <Grid className="mainBox" item md={3} sx={{ padding: '10px', margin: '0px' }}>
          <Typography sx={{ fontWeight: '800', fontSize: '25px', margin: '7px 0 11px 0' }}>
            Help us get to know you
          </Typography>
          <Box sx={{ textAlign: 'left' }}>
            <TextField
              fullWidth
              id="name"
              sx={{
                borderRadius: '8px',
                backgroundColor: '#fff',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#1DA1F2',
                  },
                  '&:hover fieldset': {
                    borderColor: 'cornflowerblue',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#1DA1F2',
                  },
                },
              }}
              placeholder="Enter Name"
              variant="outlined"
              value={name}
              onChange={(e) => handleInputAccountChange('name', e.target.value)}
              onBlur={() => validateAccountFields('name', name)}
              InputLabelProps={{ 
                  shrink: true,
                }}
              error={!!errors.name}
            />
            {errors.name && (
              <Typography className="errorText" color="error">
                {errors.name}
              </Typography>
            )}
            <TextField
              fullWidth
              id="email"
              sx={{
                marginTop: '20px',
                borderRadius: '8px',
                backgroundColor: '#fff',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#1DA1F2',
                  },
                  '&:hover fieldset': {
                    borderColor: 'cornflowerblue',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#1DA1F2',
                  },
                },
              }}
              placeholder="Email"
              variant="outlined"
              value={email}
              onChange={(e) => handleInputAccountChange('email', e.target.value)}
                InputLabelProps={{ 
                  shrink: true,
                }}
              error={!!errors.email}
            />
            {errors.email && (
              <Typography className="errorText" color="error">
                {errors.email}
              </Typography>
            )}
          </Box>
          <Grid container justifyContent={'center'} style={{ margin: '21px 0 21px 0' }}>
            <Grid item xs={12} sm={12} md={12}>
              <PrimaryButton
                title={'Continue'}
                onClick={handleAccountButtonClick}
                spinner={true}
                style={{ borderRadius: '30px', fontSize: '18px' }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
    <Modal
      open={couponModalOpen}
      onClose={handleCouponModalClose}
      sx={{ border: '0'}}
    >
      <Box className="accountDetails" sx={{ outline: 'none', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '0px', margin: '0px' }}>
        <Box className="xpanseCouponModal">
          <img src={Images.welcomeCoupon} alt="welcomeCoupon" style={{}} />
          <Typography className='percentageeOff'><span>{couponPercentageModal}</span>% OFF</Typography>
          <Typography className='couponCode'>USE CODE: <span>{couponCodeModal}</span></Typography>
        </Box>
        <Button className="couponModalButton" onClick={handleCouponModalClose}>Proceed</Button>
      </Box>
    </Modal>
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={toggleDrawer(false)}
      classes={{ paper: "drawerPaperForProfile" }}
    >
      <ProfileScreen />
    </Drawer>
    <Drawer
      anchor="left"
      open={drawerOpenForType}
      onClose={toggleDrawerForSelectingType(false)}
      classes={{ paper: "drawerPaperForProfile" }}
    >
      <AdvertiseScreen />
    </Drawer>
    </>
  )
}

export default DesktopHeader;
