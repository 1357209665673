import { Box, Typography, TextField, Grid, InputAdornment, InputLabel } from '@mui/material';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import PrimaryButton from '../../components/PrimaryButton';
import axios from 'axios';
// import { toast } from 'react-toastify';
import { API } from '../../utils/Api';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import gsap from 'gsap';
import Images from '../../utils/Images';
import toast, { Toaster } from 'react-hot-toast';

function AccountDetails() {
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const mobile = location.state?.mobile || '';
  const referralId = localStorage.getItem('referralId');
  
  useEffect(() => {
    gsap.fromTo('.mainBox', { x: '100%' }, { x: '0%', duration: 1, ease: 'power3.out' });
  }, []);

  const schema = Yup.object().shape({
    name: Yup.string().required('Name is required').matches(/^[A-Za-z][A-Za-z'-]* ?([A-Za-z'-]+ ?)*$/, 'Name must contain only letters, spaces, hyphens, and apostrophes').min(2, 'Name must be at least 2 characters').trim(),
    // name: Yup.string().required('Name is required').matches(/^[A-Za-z]+$/, 'Name must contain only letters').min(2, 'Name must be at least 2 characters').trim(),
    email: Yup.string().email('Invalid email address').required('Email is required').trim(),
  });

  const validateField = async (field, value) => {
    try {
      await schema.validateAt(field, { [field]: value });
      setErrors({ ...errors, [field]: '' });
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        setErrors({ ...errors, [field]: error.message });
      }
    }
  };

  const handleInputChange = (field, value) => {
    if (field === 'name') {
      setName(value);
      validateField('name', value);
    } else if (field === 'email') {
      setEmail(value);
      validateField('email', value);
    }
  };

  const handleButtonClick = async () => {
    const isValid = await schema.isValid({ name, email });
    if (isValid) {
      const requestData = {
        "name": name,
        "signupType": "REGULAR",
        "mobile": mobile,
      };
      if (email) {
        requestData.email = email;
      }
      if (referralId) {
        requestData.referringUser = referralId;
      }
      try {
        const response = await axios.post(`${API.BASE_URL}auth/user/create-account`, requestData);
        if (response?.data?.status === true) {
          // console.log('Account created successfully:', response?.data?.data);
          toast.success('Account created successfully!', {
            autoClose: 3000,
          });
          localStorage.setItem('token', response?.data?.data?.token);
          localStorage.setItem('userDataLogin', JSON.stringify(response?.data?.data?.createdUser));
          // if (response?.data?.data?.user?.profileImage !== null) {
          //   localStorage.setItem('userProfileImage', response?.data?.data?.user?.profileImage);
          // }
          localStorage.setItem('userProfileImage', Images.navProfile);
          localStorage.setItem('userNameHeader', response?.data?.data?.createdUser?.name);
          navigate('/welcomeScreen', { state: { couponPercentage: response?.data?.data?.userCouponData?.coupon?.discountPercent, couponCode: response?.data?.data?.userCouponData?.coupon?.couponCode } });
          localStorage.setItem('couponPercentage', response?.data?.data?.userCouponData?.coupon?.discountPercent);
          localStorage.setItem('couponCode', response?.data?.data?.userCouponData?.coupon?.couponCode);
        }
      } catch (error) {
        // console.error('Error:', error?.response?.data?.data?.message || error?.response?.data?.error);
        const errorMessage = error?.response?.data?.data?.message || error?.response?.data?.error || "Try again after sometime.";
        toast.error(errorMessage);
      } finally {
      }
    } else {
      setErrors({ name: 'Name is required', email: 'Email is required' });
    }
  };
  
  return (
    <>
      <Box className="accountDetails" sx={{ textAlign: 'left', padding: '0px', margin: '0px' }}>
        <Grid className="mainBox" item md={3} sx={{ padding: '10px', margin: '0px' }}>
          <Typography sx={{ fontWeight: '800', fontSize: '30px', margin: '21px 0 31px 0', lineHeight: '1.2' }}>
            Help us get to <br/> know you
          </Typography>
          <Box sx={{ textAlign: 'left' }}>
            <TextField
              fullWidth
              id="name"
              sx={{
                border: '3px solid #1E9CED',
                borderRadius: '8px',
                backgroundColor: '#fff',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#1DA1F2',
                  },
                  '&:hover fieldset': {
                    borderColor: 'cornflowerblue',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#1DA1F2',
                  },
                },
              }}
              placeholder="Enter Name"
              variant="outlined"
              value={name}
              onChange={(e) => handleInputChange('name', e.target.value)}
              onBlur={() => validateField('name', name)}
              InputLabelProps={{ 
                    shrink: true,
                }}
              error={!!errors.name}
            />
            {errors.name && (
              <Typography className="errorText" color="error">
                {errors.name}
              </Typography>
            )}
            <TextField
              fullWidth
              id="email"
              sx={{
                border: '3px solid #1E9CED',
                marginTop: '20px',
                borderRadius: '8px',
                backgroundColor: '#fff',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#1DA1F2',
                  },
                  '&:hover fieldset': {
                    borderColor: 'cornflowerblue',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#1DA1F2',
                  },
                },
              }}
              placeholder="Email"
              variant="outlined"
              value={email}
              onChange={(e) => handleInputChange('email', e.target.value)}
              InputLabelProps={{ 
                  shrink: true,
              }}
              error={!!errors.email}
            />
            {errors.email && (
              <Typography className="errorText" color="error">
                {errors.email}
              </Typography>
            )}
          </Box>
          <Grid container justifyContent={'center'} style={{ margin: '27px 0 27px 0' }}>
            <Grid item xs={12} sm={12} md={12}>
              <PrimaryButton
                title={'Continue'}
                onClick={handleButtonClick}
                spinner={true}
                style={{ borderRadius: '30px', fontSize: '18px' }}
              />
            </Grid>
          </Grid>
          <Box className="progressBox">
            <img className='progressDots img-fluid' src={Images.secondScreenDots} alt='ProgressDots' />      
          </Box>
        </Grid>
      </Box>
    </>
  );
}

export default AccountDetails;