import React from 'react';
import { Box, Typography } from '@mui/material';

function PrivacyPolicy() {
  return (
    <div className='privacyScreen'>
        <Typography className='privacyTitle'>PRIVACY POLICY</Typography>
        <Box className='privacyPolicyContainer'>
            <div>
                <Typography className='lastUpdatedText'>LAST UPDATED: 15-07-2024</Typography>
                <p className='privacyPolicyDesc'>This privacy policy sets forth the data protection management of BEX Technologies Private Limited (variously, “we”, “our”, “us”). This privacy policy states the collection, usage, and disclosure of personal and/or corporate information that you may provide to us by using this website and Application of “ https://bexcart.com/ “ hereinafter called as the “BEX CART”. The terms “you”, “your”, “user”, “yourself”, refer to the Users of the BEX CART.</p>
            </div>
            <div>
                <Typography className='aboutUsText'>ABOUT US</Typography>
                <p className='privacyPolicyDesc'>BEX CART is developed to offer a “subscription-based model enforced platform” onboarding the vendors /manufacturers and Resellers to sell the products catalogued on BEX CART. Which enables the subscribers to broadcast, promote the same on WhatsApp and other social media platforms to potential customers. The website is owned and maintained by BEX Technologies Private Limited having its registered office at H.No. 575/1 C/G1, Cujira, St. Cruz, Panjim, North Goa, Goa - India 403005
                </p>
            </div>
            <div>
                <Typography className='purposeText'>PURPOSE BEHIND THIS PRIVACY POLICY</Typography>
                <p className='privacyPolicyDesc'>We collect your sensitive personal information (eg. Name, address, email Id, contact number etc) when you access BEX CART, register to create your online website account or contact us. The privacy policy outlines our responsibility of handling the Sensitive Personal Information collected from you while you/the user, use BEX CART or receive our services. This Privacy Policy is a legally binding contract between You and Us.
                <br />
                This Privacy Policy is a result of legal requirements under the applicable laws. Any collection, maintenance, use, disclosure of personal data of the user is dealt with in accordance with the compliances under European Union’s General Data Protection Regulation (“GDPR”), Information Technology Act, 2000, Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011 (“the IT Rules”), Indian Constitution. We strictly adhere to industry guidelines and continuously monitor and enhance how we manage the use and security of collected data.
                </p>
            </div>
            <div>
                <Typography className='consentText'>YOUR CONSENT</Typography>
                <p className='privacyPolicyDesc'>
                By using the services on this site, you / the User agree to the terms of this Privacy Policy. When you give your information to our partners, service provider, or click on our advertisement links and provide your information, you agree and give your consent for the information to be received by us, our partners, service providers. Our privacy policy is displayed at the sign-up screen on both the website and mobile application. We ask for specific consent by clicking the check box.
                </p>
            </div>
            <div>
                <Typography className='confidentiallyText'>CONFIDENTIALITY ASSURANCE</Typography>
                <p className='privacyPolicyDesc'>
                By being vigilant about the data collection, use, sharing, retention policies followed by us, we update our privacy policy considering any changes in the policy set up and let the users/you know about the update through “LAST UPDATED ON” and expect you to bear the responsibility to read and review all such updates made before accessing our software product or accepting any services offered by us.
                </p>
            </div>
            <div>
                <Typography className='voluntaryText'>HOW LONG DO WE RETAIN THE VOLUNTARY INFORMATION</Typography>
                <p className='privacyPolicyDesc'>
                We retain your identity-related information till your account is deactivated on BEX CART. We will retain your bank details till the completion of the payment transaction and payment receipt is confirmed by us. The details given for the purposes of career prospects are retained till it is required by us to complete the interview process or till you remain in our employment. The images and voice notes shared by you are retained till you delete them yourself or till your account is deleted. The Geo-location is retained till your account subsists on BEX CART. Any other information is retained till we see you as the potential customer to deliver our services to you.
                </p>
            </div>
            <div>
                <Typography className='deletionText'>DELETION, ANONYMIZATION OF INFORMATION</Typography>
                <p className='privacyPolicyDesc'>
                The information collected from you is deleted once the purpose and period are over. However considering the business expansion need, the information such as service preferences, purchase type, quantity, value is anonymized or pseudonymized where such aggregated information does not identify a specific person and is not personal information, which is used for a variety of functions, including measuring users interest in and use of our services, conducting internal analysis, data analytics and research. We may also share anonymized or aggregated information with third parties for our or their purposes, but none of this information can be used to identify you or determine anything else personal about you.
                </p>
            </div>
        </Box>
    </div>
  )
}

export default PrivacyPolicy;
