import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import Images from '../../utils/Images';
import { Box, Typography, Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

function WelcomeScreen({ history }) {
    const navigate = useNavigate();
    const location = useLocation();
    const couponPercentage = localStorage.getItem('couponPercentage');
    const couponCode = localStorage.getItem('couponCode');

    useEffect(() => {
        console.log('Welcome coupon', couponPercentage);
        const tl = gsap.timeline({ defaults: { ease: 'power2.out', duration: 0.8 } });
        tl.set('.secondBox', { y: '100%', opacity: 0 });
        tl.fromTo('.xpanselogo', { scale: 0, opacity: 0 }, { scale: 1, opacity: 1, ease: 'bounce.out', duration: 1 });
        tl.fromTo('.welcomeScreenTypography', { scale: 0, opacity: 0 }, { scale: 1, opacity: 1, stagger: 0.2 });
        tl.to('.firstBox', { scale: 0, opacity: 0, duration: 1 }, ">1");
        tl.to('.secondBox', { y: '0%', opacity: 1, duration: 1, stagger: 0.2 }, "<");
        tl.set('.firstBox', { display: 'none' });
        tl.fromTo('.xpanseCoupon', { scale: 0, opacity: 0 }, { scale: 1, opacity: 1, stagger: 0.2 }, "<");
        tl.fromTo('.button', { scale: 0, opacity: 0 }, { scale: 1, opacity: 1, stagger: 0.2 }, "<");
    }, []);

    return (
        <Box className="welcomeScreen" sx={{ position: 'relative', height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Box className="firstBox">
                <Box className="circularTextContainer">
                    <Typography className="welcomeScreenTypography text-w">W</Typography>
                    <Typography className="welcomeScreenTypography text-e1">E</Typography>
                    <Typography className="welcomeScreenTypography text-l">L</Typography>
                    <Typography className="welcomeScreenTypography text-c">C</Typography>
                    <Typography className="welcomeScreenTypography text-o">O</Typography>
                    <Typography className="welcomeScreenTypography text-m">M</Typography>
                    <Typography className="welcomeScreenTypography text-e2">E</Typography>
                </Box>
                <Box className="xpanselogo" sx={{ textAlign: 'center' }}>
                    <img src={Images.xpanseSplashLogo} alt="Xpanselogo" style={{ width: '50%' }} />
                </Box>
            </Box>
            <Box className="secondBox">
                <Box className="xpanseCoupon">
                    <img src={Images.welcomeCoupon} alt="welcomeCoupon" style={{}} />
                    <Typography className='percentageeOff'><span>{couponPercentage}</span>% OFF</Typography>
                    <Typography className='couponCode'>USE: <span>{couponCode}</span></Typography>
                </Box>
                <Button className='button' onClick={() => navigate('/advertiseScreen') }>Proceed</Button>
            </Box>
        </Box>
    );
}

export default WelcomeScreen;