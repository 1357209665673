import { Box, Button } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import Images from '../../utils/Images';
import splashVideo from '../../assets/videos/coffe1.mp4';
import PrimaryButton from '../../components/PrimaryButton';
import { useNavigate, useParams } from 'react-router-dom';
import gsap from 'gsap';

const SplashScreen = () => {
  const navigate = useNavigate();
  const logoRef = useRef(null);

  const params = useParams();
  const referralId = params['referId'];

  useEffect(() => {
    if (referralId) {
      localStorage.setItem('referralId', referralId);
      console.log("Referral ID stored in localStorage:", referralId);
    }
  }, [referralId]); 
  
  useEffect(() => {
    const logo = logoRef.current;
    gsap.fromTo(logo, 
      { scale: 0.8 }, 
      { scale: 1.2, duration: 1, ease: "bounce.out", onComplete: () => { gsap.to(logo, { scale: 1, duration: 0.5 }); }}
    );
    const timer = setTimeout(() => {
      navigate('/mobileNumber', { replace: true });
    }, 3000);
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <Box className="splashScreen">
      <img ref={logoRef} className='splashLogo' src={Images.xpanseSplashLogo} alt='Logo' />
      <img className='img-fluid cloudR1' style={{ filter: 'invert(1)' }} src={Images.cloudR1} alt="cloud" />
      <img className='img-fluid cloudR2' style={{ filter: 'invert(1)' }} src={Images.cloudR2} alt="cloud" />
      <img className='img-fluid cloudL1' style={{ filter: 'invert(1)' }} src={Images.cloudL1} alt="cloud" />
      <img className='img-fluid cloudL2' style={{ filter: 'invert(1)' }} src={Images.cloudL2} alt="cloud" />
      <Box className="splashVideo">
        <video className='video' src={splashVideo} autoPlay loop style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        {/* <div className='buttonBox'><Button className='button' onClick={() => navigate('./mobileNumber') }>Proceed</Button></div> */}
      </Box>
    </Box>
  );
}

export default SplashScreen;