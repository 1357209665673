import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Box, Chip, Menu, MenuItem } from '@mui/material';
import Images from '../utils/Images';
import { SearchBar } from './SearchBar';

const StickyHeader = ({ isVisible, transitionDuration }) => {
    const [activeChip, setActiveChip] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleChipClick = (chip, event) => {
        setActiveChip(chip);
        if (chip === 'Sort By') {
            setAnchorEl(event.currentTarget);
        } else {
            setAnchorEl(null);
            console.log('Selected Chip:', chip);
        }
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (option) => {
        console.log('Selected Option:', option);
        setAnchorEl(null);
    };

    const chipsData = ['Sort By', 'Veg', 'Non-Veg', '₹0 - ₹300', '₹400 +'];

    return (
        <AppBar elevation={2} className="sticky-header" position="fixed" style={{
            transition: `opacity ${transitionDuration}ms`,
            opacity: isVisible ? 1 : 0,
            visibility: isVisible ? 'visible' : 'hidden'
        }}>
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box className="logo" style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                    <img className='navLogo' src={Images.xpanseNavLogo} alt="Logo" />
                    <Box className="textBox">
                        <Typography className="title">XPANSE</Typography>
                        <Typography variant='body2' className="navTag">Freshly Brewed</Typography>
                    </Box>
                </Box>
                {/* <Box sx={{ display: 'flex', justifyContent: 'space-around', flexGrow: 1 }}>
                    {chipsData.map((chip, index) => (
                        <Chip
                            key={index}
                            label={chip}
                            onClick={(event) => handleChipClick(chip, event)}
                            sx={{
                                cursor: 'pointer',
                                padding: '0 16px',
                                backgroundColor: activeChip === chip ? '#1DA1F2' : '#fff',
                                color: activeChip === chip ? '#FFF' : '#000',
                                border: activeChip === chip ? 'none' : '1px solid #BDBDBD'
                            }}
                        />
                    ))}
                </Box> */}
                <SearchBar />
            </Toolbar>
            {/* <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={() => handleMenuItemClick('Low to High')}>Low to High</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick('High to Low')}>High to Low</MenuItem>
            </Menu> */}
        </AppBar>
    );
};

export default StickyHeader;
