import { FmdGood, HomeRounded, InfoOutlined, KeyboardArrowRight, KeyboardArrowRightSharp, LocationOnOutlined, WorkRounded } from '@mui/icons-material';
import { Box, ClickAwayListener, Dialog, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { API, GOOGLE_MAP } from '../utils/Api';
import axios from 'axios';
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';
import Images from '../utils/Images';
import { Player } from '@lottiefiles/react-lottie-player';
import { QRCodeSVG } from 'qrcode.react';

const ArrivingNowOrders = () => {
  const token = localStorage.getItem("token");
  const [orderHistory, setOrderHistory] = useState([]);
  const [outletData, setOutletData] = useState(JSON.parse(localStorage.getItem('selectedStoreData')));
  const [orderDetailsModal, setOrderDetailsModal] = useState(false);
  const [orderDetailData, setOrderDetailData] = useState([]);
  const navigate = useNavigate();
  const mapRef = useRef(null);
  const [outletMarker, setOutletMarker] = useState(Images.xpanseMapMarker);
  const [currentCoordinates, setCurrentCoordinates] = useState({ lat: 0, lng: 0 });
  const [outlet, setOutlet] = useState({ lat: 0, lng: 0 });
  const orderStatus = orderDetailData?.order?.orderStatus;
  const gridMd = orderStatus === "DELIVERED" ? 12 : 8;
  
  const containerStyle = {
    width: "95%",
    height: "65%",
    marginTop: "8%",
    border: "4px solid #fff",
    borderRadius: "12px"
  };

  const calculateRemainingTime = (readyTime) => {
    const currentTime = new Date();
    const [hours, minutes] = readyTime.split(':').map(Number);
    const readyTimeDate = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate(), hours, minutes);
    const diff = readyTimeDate - currentTime; // Difference in milliseconds
    const remainingMinutes = Math.round(diff / 60000); // Convert milliseconds to minutes
    return remainingMinutes > 0 ? remainingMinutes : 0; // Ensure it doesn't go negative
  };
  
  const getOrders = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}order/getConsumerOrders/${outletData._id}?filterByArrivalTime=true&filterByCurrentDay=true`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const filteredOrders = response.data.data.orders.filter(order => order?.order?.orderStatus === 'CONFIRMED' || order?.order?.orderStatus === 'COMPLETED' || order?.order?.orderStatus === 'READY-TO-PICK');
      setOrderHistory(filteredOrders);
      // console.log("Orders history fetched successfully", response.data.data.orders);
    } catch (error) {
      console.error('Error while fetching orders history data:', error);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setOrderHistory((prevOrderHistory) => [...prevOrderHistory]);
    }, 60000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getOrders();
    setOutletData(JSON.parse(localStorage.getItem('selectedStoreData')));
  }, []);

  const handleNavigateToOrderDetail = (orderDetails) => {
    setOrderDetailData(orderDetails); 
    // dispatch(orderDetails.order.readyTime);
    // console.log('aaaaaaaaaaaaaaaa',orderDetails);
    const isDelivery = orderDetails.order.ordersType === 'DELIVERY';
    const targetScreen = isDelivery ? '/orderDetailsDelivery' : '/orderDetails';
    if (window.innerWidth <= 768) {
      navigate(targetScreen, { state: { orderDetails, screenLocation: "ORDERHISTORY" } });
    } else {
      // setOrderDetailsModal(true);
      handleOpenOrderDetailsModal();
    }
  };

  const handleOpenOrderDetailsModal = () => {
    setOrderDetailsModal(true);
  }

  const handleCloseOrderDetailsModal = () => {
    setOrderDetailsModal(false);
  }

  const onLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const onUnmount = useCallback(() => {
    mapRef.current = null;
  }, []);

  useEffect(() => {
    let interval;
    const fetchCurrentCoordinates = async () => {
      try {
          const response = await axios.get(`${API.BASE_URL}order/tracking/${orderDetailData?.order?.deliveryOrderId}`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          const { latitude, longitude } = response.data.data.current;
          setCurrentCoordinates({ lat: parseFloat(latitude), lng: parseFloat(longitude) });
          // console.log('Successfully fetched current coordinates:', currentCoordinates);
      } catch (error) {
        console.error('Error while fetching current coordinates:', error);
      }
    };
    if (orderDetailData?.order?.deliveryOrderId) {
      fetchCurrentCoordinates();
      interval = setInterval(fetchCurrentCoordinates, 3000);
    }
    return () => clearInterval(interval);
  }, [orderDetailData?.order?.deliveryOrderId]);

  const totalSavings = useMemo(() => {
    return orderDetailData?.orderItems?.reduce((acc, item) => {
      const savingsPerItem = (item?.product?.mrp - item?.product?.offerPrice) * item?.quantity;
      const savingsPerAddons = item?.addons?.reduce((addonAcc, addon) => {
        return addonAcc + ((addon?.mrp - addon?.offerPrice) * (item?.quantity || 0));
      }, 0);
      return acc + savingsPerAddons + savingsPerItem;
    }, 0);
  }, [orderDetailData?.orderItems]);

  const totalCouponDiscount = orderDetailData?.orderItems?.reduce((total, item) => {
    return total + (item?.discount);
  }, 0);

  const totalOfferPrice = useMemo(() => {
    return orderDetailData?.orderItems?.reduce((acc, item) => {
      const totalOfferPrice = (item?.product?.offerPrice) * item?.quantity;
      return acc + totalOfferPrice;
    }, 0);
  }, [orderDetailData?.orderItems]);

  const totalSizeUpgradeAmount = useMemo(() => {
    return orderDetailData.orderItems?.reduce((acc, item) => {
      return acc + (item?.sizeUpgradePayload?.newAddonItemOfferPrice || 0) - (item?.sizeUpgradePayload?.prevAddonItemOfferPrice || 0);
    }, 0);
  }, [orderDetailData?.orderItems]);

  const totalTaxAmount = (orderDetailData?.order?.totalTaxAmount || 0) + (orderDetailData?.order?.GSTPackingCharges || 0) +  (orderDetailData?.order?.GSTPlatformFees || 0);
  const totalSum = totalTaxAmount + totalOfferPrice;
  const formattedSum = totalSum?.toFixed(2)?.replace(/\.?0*$/, '');
  const totalQuantityInOrder = orderDetailData?.orderItems?.reduce((total, item) => { return total + item?.quantity; }, 0);

  useEffect(() => {
    const fetchCurrentCoordinates = async () => {
      try {
        const response = await axios.get(`${API.BASE_URL}order/tracking/${orderDetailData?.order?.deliveryOrderId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setOutlet({ lat: parseFloat(orderDetailData?.order?.outlet?.coordinates?.latitude), lng: parseFloat(orderDetailData?.order?.outlet?.coordinates?.longitude)});
        setOutletMarker(Images.xpanseMapMarker);
        // console.log('Successfully fetched current coordinates:', response.data.data);
      } catch (error) {
        console.error('Error while fetching current coordinates:', error);
      }
    };
    fetchCurrentCoordinates();
    const intervalId = setInterval(fetchCurrentCoordinates, 300000);
    return () => {
      clearInterval(intervalId);
    };
  }, [orderDetailData?.order?.deliveryOrderId, token]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentCoordinates({ lat: parseFloat(orderDetailData?.order?.deliveryDetails?.coordinates?.latitude), lng: parseFloat(orderDetailData?.order?.deliveryDetails?.coordinates?.longitude) });
      setOutlet({ lat: parseFloat(orderDetailData?.order?.outlet?.coordinates?.latitude), lng: parseFloat(orderDetailData?.order?.outlet?.coordinates?.longitude) });
    }, 1000);
    return () => clearTimeout(timer);
  }, [orderDetailData]);

  useEffect(() => {
    if (orderDetailData && orderDetailData?.order?.deliveryDetails?.coordinates && mapRef.current) {
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend(new window.google.maps.LatLng(currentCoordinates?.lat, currentCoordinates?.lng));
      bounds.extend(new window.google.maps.LatLng(outlet?.lat, outlet?.lng));
      mapRef.current.fitBounds(bounds);
    }
  }, [orderDetailsModal, orderDetailData, currentCoordinates, outlet]);

  const [openTaxTooltip, setTaxOpenTooltip] = useState(false);

  const handleTaxTooltipOpen = () => {
    setTaxOpenTooltip(true);
  };

  const handleTaxTooltipClose = () => {
    setTaxOpenTooltip(false);
  };

  const taxTooltipContent = (
    <Box>
      <Typography variant="body2" padding={'2px 0 2px 0'} sx={{ display: 'flex', justifyContent: 'space-between'}} component="p">
          Base Tax: <span>₹{orderDetailData?.order?.totalTaxAmount}</span>
      </Typography>
      {orderDetailData?.order?.ordersType === 'DELIVERY' && (
          <Typography variant="body2" padding={'2px 0 2px 0'} sx={{ display: 'flex', justifyContent: 'space-between'}} component="p">
              GST Packing Charges: <span>₹{orderDetailData?.order?.GSTPackingCharges}</span>
          </Typography>
      )}
      <Typography variant="body2" padding={'2px 0 2px 0'} sx={{ display: 'flex', justifyContent: 'space-between'}} component="p">
          GST Platform Fees: <span>₹{orderDetailData?.order?.GSTPlatformFees}</span>
      </Typography>
      <Typography variant="body2" padding={'2px 0 2px 0'} component="p" sx={{ borderTop: '1px solid #fff', display: 'flex', justifyContent: 'space-between' }}>
          Total Tax: <span>₹ {totalTaxAmount?.toFixed(2)}</span>
      </Typography>
    </Box>
  );
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const dayOfWeek = days[date.getUTCDay()];
    const month = months[date.getUTCMonth()];
    const year = date.getUTCFullYear();
    const day = date.getUTCDate();
    return ` ${day}-${month}-${year}`;
    // return `${dayOfWeek}, ${day} - ${month} - ${year}`;
  };

  const calculateTotalPrice = (item) => {
    let totalPrice = item?.product?.offerPrice * item?.quantity;
    item?.addons?.forEach(addon => {
    totalPrice += addon?.offerPrice * item?.quantity;
    });
    return totalPrice?.toFixed(2).replace(/\.?0*$/, '');
  };

  const totalMrpOrder = useMemo(() => {
    return orderDetailData?.orderItems?.reduce((acc, item) => {
      const productMrp = (item?.product?.mrp || 0) * (item?.quantity || 0);
      const addonsMrp = item?.addons?.reduce((addonAcc, addon) => {
        return addonAcc + ((addon?.mrp || 0) * (item?.quantity || 0));
      }, 0);
      return acc + productMrp + addonsMrp;
    }, 0);
  }, [orderDetailData?.orderItems]);

  const totalOfferPriceOrder = useMemo(() => {
    return orderDetailData?.orderItems?.reduce((acc, item) => {
      const productOfferPrice = (item?.product?.offerPrice || 0) * (item?.quantity || 0);
      const addonsOfferPrice = item?.addons?.reduce((addonAcc, addon) => {
        return addonAcc + ((addon?.offerPrice || 0) * (item?.quantity || 0));
      }, 0);
      return acc + productOfferPrice + addonsOfferPrice;
    }, 0);
  }, [orderDetailData?.orderItems]);

  return (
    <>
    <Box className="arrivingNowOrders">
      <Box className="arrivingNewOrdersContainer">
        {orderHistory?.map((order) => {
          const remainingMinutes = calculateRemainingTime(order?.order?.readyTime);
          return (
            <Grid container className="arrivingNowOrderBox" onClick={() => handleNavigateToOrderDetail(order)}>
              <Grid item xs={3} className="timeBox">
                <Typography className="arrivingText">{order?.ordersType === 'DELIVERY' ? 'Arriving in' : 'Ready in'}</Typography>
                <Typography className="minutes">{remainingMinutes} mins</Typography>
              </Grid>
              <Grid item xs={9} className="detailsBox">
                <Box>
                  <Typography className="orderNo">#{order?.order?.order_sequence}{order?.order?.order_no} Order Received</Typography>
                  <Typography className="outletName">{order?.order?.outlet?.name}</Typography>
                  <>
                  {order?.order?.ordersType !== "DELIVERY" ? (
                    <Typography className="addressDetails"><span style={{ fontWeight: '500' }}>Picking at:</span> {order?.order?.outlet?.name}, {order?.order?.outlet?.address}, {order?.order?.outlet?.city}, {order?.order?.outlet?.state}</Typography>
                  ) : (
                    <Typography className="addressDetails"><span style={{ fontWeight: '500' }}>Delivering at:</span> {order?.order?.deliveryDetails?.recieverName} {order?.order?.deliveryDetails?.addressLine1}, {order?.order?.deliveryDetails?.addressLine2}, {order?.order?.deliveryDetails?.city}, {order?.order?.deliveryDetails?.state}, {order?.order?.deliveryDetails?.pincode}</Typography>
                  )}
                  </>
                </Box>
                <Box>
                  <KeyboardArrowRight className="arrow" />
                </Box>
              </Grid>
            </Grid>
          );
        })}
      </Box>
    </Box>
    <Dialog maxWidth className="orderDetailsModal" open={orderDetailsModal} onClose={handleCloseOrderDetailsModal}>
      <Box className="orderDetails">
        <Grid container>
          {orderDetailData?.order?.orderStatus !== "DELIVERED" && (
            orderDetailData?.order?.ordersType === "DELIVERY" ? (
              <Grid item md={4}>
                <LoadScript googleMapsApiKey={GOOGLE_MAP.GOOGLE_MAP_KEY}>
                  <Box sx={{ backgroundColor: '#fff', color: '#186B8C', fontWeight: '600', padding: '8px 20px', borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px', width: '120px', display: 'flex', justifyContent: 'center', margin: '0 auto -10px auto' }}>
                    Order Id: #{orderDetailData?.order?.order_sequence}{orderDetailData?.order?.order_no}
                  </Box>
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                    center={currentCoordinates}                            
                    options={{ mapTypeControl: false, zoomControl: false, fullscreenControl: false, keyboardShortcuts: false, streetViewControl: false }}
                  >
                    <MarkerF
                      position={currentCoordinates}
                      title="Your Location"
                    />
                    <MarkerF
                      position={outlet}
                      icon={outletMarker}
                      title="Outlet"
                    />
                  </GoogleMap>
                </LoadScript>
                <Box className="orderDeliveryDetail">
                    <Box sx={{ display: 'flex' }}>
                        <img className="successGifDelivery" src={Images.successTick} alt='successTick' />
                        <Typography className="titleDelivery">Your order has been placed successfully!</Typography>
                    </Box>
                    <Box sx={{ padding: '0 12px 0px 12px'}}>
                        <Box sx={{ display: 'flex' }}>
                            <Typography variant='body2' sx={{ fontWeight: '600' }}>Delivering to :</Typography>
                            {orderDetailData?.order?.deliveryDetails?.type === 'HOME' && <HomeRounded sx={{ color: '#1E9CED' }} />}
                            {orderDetailData?.order?.deliveryDetails?.type === 'WORK' && <WorkRounded sx={{ color: '#1E9CED' }} />}
                            {orderDetailData?.order?.deliveryDetails?.type !== 'HOME' && orderDetailData?.order?.deliveryDetails?.type !== 'WORK' && <LocationOnOutlined sx={{ color: '#1E9CED' }} />}
                            <Typography variant='body2' sx={{ fontWeight: '600' }}>{orderDetailData?.order?.deliveryDetails?.type}</Typography>
                        </Box>
                        <Typography variant='body2' >{orderDetailData?.order?.deliveryDetails?.recieverName} {orderDetailData?.order?.deliveryDetails?.addressLine1}, {orderDetailData?.order?.deliveryDetails?.addressLine2}, {orderDetailData?.order?.deliveryDetails?.city}, {orderDetailData?.order?.deliveryDetails?.state}, {orderDetailData?.order?.deliveryDetails?.pincode}</Typography>
                    </Box>
                    <img className="couponBorderInverted" src={Images.couponBorderInverted} />
                </Box>
              </Grid>
            ) : (
              <Grid item md={4}>
                <Player autoplay loop={false} keepLastFrame={true} src={Images.successGif} className="successGif"></Player>
                <Box className="qrBox">
                  <Typography className="orderNo">#{orderDetailData?.order?.order_sequence}{orderDetailData?.order?.order_no}</Typography>
                  <QRCodeSVG className="qrCode" value={orderDetailData?.order?.scanOrderId} />
                  <Typography className="scanText">Please Scan The QR Code At Store</Typography>
                </Box>
              </Grid>
            )
          )}
          <Grid item md={gridMd}>
            <Grid container>
              <Grid item md={12}>
                {orderDetailData?.order?.orderStatus !== "DELIVERED" ? (
                  <Typography className="placedTitle" >Your Order Has Been Placed Successfully!</Typography>
                ) : (
                  <Typography className="placedTitle">Your Order Has Been Delivered Successfully!</Typography>
                )}
              </Grid>
              <Grid item md={6}>
                <Box className="marginTop orderFinalSummary">
                  <img className="couponBorder" src={Images.couponBorder} />
                  <Box className="orderItemsList" style={{ overflow: 'auto'  }}>
                    <Typography sx={{ textAlign: 'center', color: '#186B8C', fontWeight: '600' }}>Item Summary</Typography>
                    {orderDetailData?.orderItems?.map((item, index) => (
                      <Grid key={item?._id} container className="item" sx={{ borderBottom: index === orderDetailData.orderItems.length - 1 ? 'none' : '1px solid #C5C5C5' }}>
                        <Grid item className="detail-grid" xs={6}>
                            <Typography className="item-name">{item?.product?.name}</Typography>
                        </Grid>
                        <Grid item className="detail-grid" xs={3} sx={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
                          <Typography className="item-name">Qty - {item?.quantity}</Typography>
                        </Grid>
                        <Grid item className="detail-grid" xs={3} sx={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
                            <Typography className="item-cost">₹{calculateTotalPrice(item)}</Typography>
                        </Grid>
                        <Grid item md={12}>
                          {item?.addons?.length > 0 && (
                            <Stack flexDirection={'row'}>
                              {item.addons.map((addon, index) => (
                                <Typography key={index} sx={{ paddingRight: '4px' }} className="item-attribute">
                                  {addon.selectedValue}{index === item.addons.length - 1 ? '.' : ', '} 
                                </Typography>
                              ))}
                            </Stack>
                          )}
                        </Grid>
                      </Grid>
                    ))}
                  </Box>
                </Box>
                <Box className="addressBox">
                  <Box className="firstBox">
                    <Box className="locationIconBox" sx={{ display: 'flex', alignItems: 'center' }}>
                    <FmdGood className="icon" /> 
                    {orderDetailData?.order?.ordersType !== "DELIVERY" ? (
                      <Typography className="text">Pick Up</Typography>
                    ) : (
                      <Typography className="text">From Outlet</Typography>
                    )}
                    </Box>
                    {orderDetailData?.order?.ordersType !== "DELIVERY" ? ( <Typography className="time">{orderDetailData?.order?.readyTime} Pick Up</Typography> ) : ( <Typography className="time">Making in {orderDetailData?.order?.totalMakingTime}</Typography> )}
                  </Box>
                  <Typography className="secondBox">{orderDetailData?.order?.outlet?.name}, {orderDetailData?.order?.outlet?.city}, {orderDetailData?.order?.outlet?.state}</Typography>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box className="orderFinalSummary">
                  <Box className="orderSummaryTable">
                    <Typography className="head">Order Summary</Typography>
                    <Box className="row">
                        <Typography className="itemHead">Item(s)</Typography>
                        <Typography className="itemValue">{totalQuantityInOrder}</Typography>
                    </Box>
                    <Box className="row">
                      <Typography className="itemHead">Order Total</Typography>
                      <Typography className="itemValue"><span style={{ fontSize: '14px', textDecoration: 'line-through', color: 'gray' }}>₹{totalMrpOrder}</span> ₹{totalOfferPriceOrder}</Typography>
                    </Box>  
                    <Box className="row">
                        <Typography className="itemHead" sx={{ display: 'flex', alignItems: 'center'}}>Tax
                            <ClickAwayListener ClickAwayListener onClickAway={handleTaxTooltipClose}>
                            <Tooltip
                              PopperProps={{ disablePortal: true, }}
                              onClose={handleTaxTooltipClose}
                              open={openTaxTooltip}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title={taxTooltipContent}
                            >
                              <IconButton onClick={handleTaxTooltipOpen} size="small" sx={{ padding: 0, marginLeft: '5px' }}>
                                <InfoOutlined sx={{ fontSize: '12px' }} />
                              </IconButton>
                            </Tooltip>
                            </ClickAwayListener>
                        </Typography>
                        <Typography className="itemValue">₹ {totalTaxAmount?.toFixed(2)}</Typography>
                    </Box>
                    {orderDetailData?.order?.platformFees > 0 && (
                      <Box className="row">
                          <Typography className="itemHead">Platform Fees</Typography>
                          <Typography className="itemValue">
                          {orderDetailData.order.platformFees.toFixed(2) === "0.00" ? "Free" : `₹ ${orderDetailData.order.platformFees.toFixed(2)}`}
                          </Typography>
                      </Box>
                    )}
                    {orderDetailData?.order?.ordersType === 'DELIVERY' && (
                        <>
                        {orderDetailData?.order?.packagingCharges > 0 && (
                            <Box className="row">
                            <Typography className="itemHead">Packaging Charges</Typography>
                            <Typography className="itemValue">
                                {orderDetailData.order.packagingCharges.toFixed(2) === "0.00" ? "Free" : `₹ ${orderDetailData.order.packagingCharges.toFixed(2)}`}
                            </Typography>
                            </Box>
                        )}
                        {orderDetailData?.order?.deliveryCharges > 0 && (
                            <Box className="row">
                            <Typography className="itemHead">Shipping</Typography>
                            <Typography className="itemValue">
                                {orderDetailData.order.deliveryCharges.toFixed(2) === "0.00" ? "Free" : `₹ ${orderDetailData.order.deliveryCharges.toFixed(2)}`}
                            </Typography>
                            </Box>
                        )}
                        </>
                    )}
                    {totalCouponDiscount > 0 && (
                      <Box className="row">
                          <Typography className="itemHead">Coupon Discount</Typography>
                          <Typography className="itemValue" sx={{ color: 'green' }}>- ₹{totalCouponDiscount?.toFixed(2)}</Typography>
                      </Box>
                    )}
                    {/* {totalSizeUpgradeAmount > 0 &&
                        <Box className="row">
                            <Typography className="itemHead">Coupon Discount</Typography>
                            <Typography className="itemValue" sx={{ color: 'green' }}>- ₹{totalSizeUpgradeAmount?.toFixed(2)}</Typography>
                        </Box>
                    } */}
                    {orderDetailData?.order?.coupon && totalSizeUpgradeAmount <= 0 && ( <Typography className="itemHead" sx={{ paddingBottom: '4px', color: '#1E9CED'}}>( {orderDetailData?.order?.coupon?.couponCode} )</Typography>)}
                    <Box className="row" sx={{ borderTop: '1px dashed #707070', borderBottom: '1px solid #707070'}}>
                        <Typography className="itemHead" sx={{ fontWeight: '600'}}>Total Payable</Typography>
                        <Typography className="itemValue" sx={{ fontWeight: '600'}}>₹ {orderDetailData?.order?.grandTotal?.toFixed(2).replace(/\.?0*$/, '')}</Typography>
                    </Box>
                  </Box>
                  <Box className="savedBox">
                      <Typography className="text">You Saved</Typography>
                      <Typography>₹ {((parseFloat(totalSavings || 0) + parseFloat(orderDetailData?.order?.totalDiscount || 0)).toFixed(2).replace(/\.?0*$/, ''))}</Typography>
                  </Box>
                  <Box className="paymentBox">
                      <Typography className="head">Payment Details</Typography>
                      <Box className="payDetails">
                          <Typography className="payDetails">Payment mode: {orderDetailData?.order?.paymentMode}</Typography>
                          <Typography className="payDetails">Date: {formatDate(orderDetailData?.order?.createdAt)}</Typography>
                      </Box>
                      <Typography onClick={handleCloseOrderDetailsModal} className="button">Continue Shopping</Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
    </>
  )
}

export default ArrivingNowOrders;
