import React, { useState, createContext, useContext } from 'react';
import { Box, TextField, InputAdornment, IconButton, Dialog, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import MicIcon from '@mui/icons-material/Mic';
import { Mic } from '@mui/icons-material';
import Images from '../utils/Images';
import { Player } from '@lottiefiles/react-lottie-player';

const SearchContext = createContext();
const useSearch = () => {
  return useContext(SearchContext);
};

const SearchBar = () => {
  const [localText, setLocalText] = useState('');
  const { setSearchText } = useSearch();
  const [isListening, setIsListening] = useState(false);
  const [openModal, setopenModal] = useState(false);
  const [spokenText, setSpokenText] = useState('');

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setLocalText(value);
    console.log(value);
    setSearchText(value);
  };

  const handleSearch = () => {
    setSearchText(localText);
  };

  const handleOpenModal = ()=> {
    setopenModal(true);
  }

  const handleCloseModal = () => {
    setopenModal(false);
  }

  const handleVoiceSearch = () => {
    const recognition = new window.webkitSpeechRecognition();
    setLocalText('');
    recognition.lang = 'en-US';
    recognition.onstart = () => {
      setIsListening(true);
      setopenModal(true);
    };
    const timeoutId = setTimeout(() => {
      recognition.stop();
      console.log("Timeout - stopping recognition");
    }, 4000); 
    recognition.onend = () => {
      clearTimeout(timeoutId);
      setIsListening(false);
      setopenModal(false);
    };
    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      console.log("Spoken text:", transcript);
      setSpokenText(transcript);
      setLocalText(transcript);
      setSearchText(transcript);
      recognition.stop();
    };
    recognition.start();
  };

  return (
    <>
    <Box className="searchBar">
      <TextField
        placeholder={isListening ? "Listening for your choice..." : "Search for the perfect brew"}
        className="bar"
        variant="outlined"
        size="small"
        fullWidth
        value={localText}
        onChange={handleSearchChange}
        sx={{ backgroundColor: '#fff'}}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton style={{ color: '#1DA1F2' }} onClick={handleSearch}>
                <SearchIcon />
              </IconButton>
              <span style={{ width: '1px', height: '1.5em', backgroundColor: '#ccc', margin: '0 2px' }}></span>
              <IconButton style={{ color: '#1DA1F2' }} onClick={handleVoiceSearch}>
                <Mic />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
    <Dialog open={openModal} onClose={() => setopenModal(false)}>
      <Box sx={{ backgroundColor: 'transparent', p: 3, textAlign: 'center' }}>
        <Typography variant="h6">{isListening ? "Listening..." : "Processing..."}</Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>{spokenText}</Typography>
        <Player autoplay loop src={Images.micAnimation} className="micAnimation" />
      </Box>
    </Dialog>
    </>
  );
};

const SearchProvider = ({ children }) => {
  const [searchText, setSearchText] = useState('');

  return (
    <SearchContext.Provider value={{ searchText, setSearchText }}>
      {children}
    </SearchContext.Provider>
  );
};

export { SearchBar, SearchProvider, useSearch };