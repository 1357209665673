import { Box, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import useRazorpay from "react-razorpay";
import { useLocation, useNavigate } from "react-router-dom";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PickUpCartComponent from "../../components/PickUpCartComponent";
import { API, RAZOR_PAY } from "../../utils/Api";
import axios from "axios";
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

const PaymentScreen = () => {
  const [Razorpay] = useRazorpay();
  const location = useLocation();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const orderType = localStorage.getItem("selectedOrderType");
  // const [store, setOrderDetails] = useState([]);
  // const [storeId, setStoreId] = useState('');
  const [userData, setUserData] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [outletData, setOutletData] = useState(JSON.parse(localStorage.getItem('selectedStoreData')));
  const grandTotal = location?.state?.grandTotal || 0;
  const storeIdData = location?.state?.storeId || 0;
  // const [store, setStore] = useState([]);
  const [razorPayData, setRazorPayData] = useState([]);
  const [orderCreateData, setOrderCreateData] = useState([]);
  const activePickTime = useSelector(state => state.time.activePickTime);
  const arrivalTimeDurationInMinutes = useSelector(state => state.time.newDuration);
  const [deliveryAddressId, setDeliveryAddressId] = useState('');
  
  function convertTo24Hour(time) {
    const [timePart, modifier] = time?.split(' ');
    let [hours, minutes] = timePart?.split(':');
    if (hours === '12') {
      hours = '00';
    }
    if (modifier?.toLowerCase() === 'pm') {
      hours = parseInt(hours, 10) + 12;
    }
    return `${hours}:${minutes}`;
  }

  const convertedTime = convertTo24Hour(activePickTime);
  
  const createOrder = async () => {
    try {
      const requestBody = {
        ordersType: orderType,
        paymentMode: "ONLINE",
        outlet: storeIdData,
        readyTime: activePickTime
      };
      const response = await axios.post(`${API.BASE_URL}order/create`, requestBody, {
        headers: {
          Authorization : `Bearer ${token}`
        }
      });
      console.log("Order created successfully", response?.data?.data);
      setOrderCreateData(response?.data?.data);
    } catch (error) {
      console.error('Error while making the POST request:', error?.response);
      navigate('/productsListing')
      toast.error(error?.response?.data?.data?.title);
    }
  };

  useEffect(() => {
    createOrder();
    setUserData(JSON.parse(localStorage.getItem('userDataLogin')));
    const selectedDeliveryAddress = localStorage.getItem("selectedAddress");
    if (selectedDeliveryAddress) {
      const address = JSON.parse(selectedDeliveryAddress);
      setDeliveryAddressId(address._id);
    }
  },[])
  
  const handleNetBankingPayment = useCallback(async () => {
    const options = {
        key: RAZOR_PAY?.RAZOR_PAY_KEY,
        order_id: orderCreateData?.order?.id,
        amount: orderCreateData?.order?.amount,
        currency: "INR",
        name: "Xpanse",
        prefill: {
          contact: userData?.mobile,
          email: userData?.email,
          method: "netbanking",
        },
        theme: {
          hide_topbar: false,
          color: "#1DA1F2",
          backdrop_color: "rgba(29, 161, 242, 0.5)",
        },
        handler: function (response) {
          console.log("Payment Successfull", response);
          setRazorPayData(response);
          toast.success("Payment Successfull!!!");
          verifyPayment(response);
        },
    };
    if (Razorpay) {
        const rzpay = new Razorpay(options);
        rzpay.open(options);
        rzpay.on("payment.failed", function (response) {
          console.log(response.error.code);
          console.log(response.error.description);
          console.log(response.error.source);
          console.log(response.error.step);
          console.log(response.error.reason);
        });
    }
  }, [Razorpay, orderCreateData]);
    
  const handleUpiPayment = useCallback(async () => {
    const options = {
        key: RAZOR_PAY?.RAZOR_PAY_KEY,
        order_id: orderCreateData?.order?.id,
        amount: orderCreateData?.order?.amount,
        currency: "INR",
        name: "Xpanse",
        theme: {
            hide_topbar: false,
            color: "#1DA1F2",
            backdrop_color: "rgba(29, 161, 242, 0.5)",
        },
        prefill: {
          contact: userData?.mobile,
          email: userData?.email,
          method: "upi",
        },
        handler: function(response) {
          console.log("Payment Successfull", response);
          setRazorPayData(response);
          toast.success("Payment Successfull!!!");
          verifyPayment(response);
        }
    };
    if (Razorpay) {
      const rzpay = new Razorpay(options);
      rzpay.open(options);
      rzpay.on("payment.failed", function (response) {
        console.log(response.error.code);
        console.log(response.error.description);
        console.log(response.error.source);
        console.log(response.error.step);
        console.log(response.error.reason);
      });
    }
  }, [Razorpay, orderCreateData]);
  
  const handleCardPayment = useCallback(async () => {
    const options = {
      key: RAZOR_PAY?.RAZOR_PAY_KEY,
      order_id: orderCreateData?.order?.id,
      amount: orderCreateData?.order?.amount,
      currency: "INR",
      name: "Xpanse",
      theme: {
        hide_topbar: false,
        color: "#1DA1F2",
        backdrop_color: "rgba(29, 161, 242, 0.5)",
      },
      prefill: {
        contact: userData?.mobile,
        email: userData?.email,
        method: "card",
      },
      handler: function(response) {
        console.log("Payment Successfull", response);
        setRazorPayData(response);
        toast.success("Payment Successfull!!!");
        verifyPayment(response);
      }
    };
    if (Razorpay) {
      const rzpay = new Razorpay(options);
      rzpay.open(options);
      rzpay.on("payment.failed", function (response) {
        console.log(response.error.code);
        console.log(response.error.description);
        console.log(response.error.source);
        console.log(response.error.step);
        console.log(response.error.reason);
      });
    }
  }, [Razorpay, orderCreateData]);
  
  const verifyPayment = async (razorPayData) => {
    try {
      const requestBody = {
        razorpayOrderId: razorPayData.razorpay_order_id,
        razorpayPaymentId:  razorPayData.razorpay_payment_id,
        razorpaySignature: razorPayData.razorpay_signature,
        ordersType: orderType,
        paymentMode: "ONLINE",
        outlet: outletData._id,
        readyTime: convertedTime,
        arrivalTimeDurationInMinutes: arrivalTimeDurationInMinutes
        // ...(orderType === "PICKUP" && { readyTime: convertedTime, arrivalTimeDurationInMinutes: arrivalTimeDurationInMinutes })
      };
      if (orderType !== "PICKUP") {
        requestBody.deliveryDetails = deliveryAddressId;
      }
      const response = await axios.post(`${API.BASE_URL}order/verifyPayment`, requestBody, {
        headers: {
          Authorization : `Bearer ${token}`
        }
      });
      console.log("Order Payment Verified successfully", response.data);
      if (response?.data?.data && response?.data?.data?.order && response?.data?.data?.order?.ordersType === "DELIVERY") {
        navigate('/orderDetailsDelivery', { state: { orderDetails: response.data.data, screenLocation: "PAYMENT" } });
      } else {
        navigate('/orderDetails', { state: { orderDetails: response.data.data, screenLocation: "PAYMENT" } });
      }
    } catch (error) {
      console.error('Error while making the POST Order Payment Verified request:', error.response);
    }
  };

  return (
    <>
    <Box className="paymentScreen">
      <PickUpCartComponent />
      <Typography className="head">Pay using UPI Id's</Typography>
      <Box onClick={handleUpiPayment} className="paymentTypeBox">
        <AddCircleIcon className="icon" />
        <Box className="textBox">
          <Typography className="title">Pay with UPI ID</Typography>
          <Typography className="title2">Any registered UPI ID</Typography>
        </Box>
      </Box>
      <Typography className="head">Credit Or Debit Cards</Typography>
      <Box onClick={handleCardPayment} className="paymentTypeBox">
        <AddCircleIcon className="icon" />
        <Box className="textBox">
          <Typography className="title">Pay with your card</Typography>
        </Box>
      </Box>
      <Typography className="head">Pay using Net Banking</Typography>
      <Box onClick={handleNetBankingPayment} className="paymentTypeBox">
        <AddCircleIcon className="icon" />
        <Box className="textBox">
          <Typography className="title">Select Your Bank</Typography>
        </Box>
      </Box>
    </Box>
    </>
  );
};

export default PaymentScreen;