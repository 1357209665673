import React, { useState, useEffect } from "react";
import { Box, Typography, CircularProgress, Button, TextField, MenuItem, InputAdornment, IconButton } from "@mui/material";
import axios from "axios";
import { API } from "../../../utils/Api";
import { useNavigate } from "react-router-dom";
import Images from "../../../utils/Images";
import * as Yup from "yup";
import { Formik } from "formik";
import { DateRange, EditAttributes, EditOff, ModeEdit } from "@mui/icons-material";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import toast from "react-hot-toast";

const EditProfile = () => {
  const token = localStorage.getItem("token");
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    mobile: ""
  });
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [formattedDate, setFormattedDate] = useState('');
  const [dob, setDob] = useState('');
  const [date, setDate] = useState('');
  const [isDirty, setIsDirty] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}user/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUserData(response.data.data.user);
      console.log('User account fetched successfully',response.data.data);
      const formattedDate = new Date(response.data.data.user.createdAt).toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
      setFormattedDate(formattedDate);
      setFormValues({
        name: response.data.data.user.name,
        email: response.data.data.user.email,
        mobile: response.data.data.user.mobile,
        dob: response.data.data.user.dob,
        gender: response.data.data.gender
      });
      setDob(response.data.data.user.dob);
      const formattedDob = new Date(response.data.data.user.dob).toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' });
      setDob(formattedDob);
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      fetchUserData();
    }
  }, [token]);

  const handleAddProfile = async () => {
    const formData = new FormData();
    const fileInput = document.getElementById('profileImageInput');
    formData.append('profileImage', fileInput.files[0]);
    try {
      const response = await axios.put(`${API.BASE_URL}user/profile/update-image`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        },
      });
      console.log('Profile image uploaded successfully:', response.data);
      toast.success("Profile image uploaded successfully!!!");
      fetchUserData();
    } catch (error) {
      console.error('Error uploading profile image:', error);
      toast.error("Error uploading profile image, Try later.");
    }
  };

  const genders = [
    {
      value: 'MALE',
      label: 'Male',
    },
    {
      value: 'FEMALE',
      label: 'Female',
    },
    {
      value: 'OTHER',
      label: 'Others',
    },
  ];

  const handleUpdateProfile = async (values) => {
    const dateString = dob;
    const dateObject = new Date(dateString);
    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObject.getDate().toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    try {
      const response = await axios.put(`${API.BASE_URL}user/profile/update`, {
        name: values.name,
        email: values.email,
        mobile: values.mobile,
        dob: formattedDate,
        gender: values.gender
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setName(values.name);
      setEmail(values.email);
      setMobile(values.mobile);
      // window.location.reload();
      // navigate('/productsListing');
      console.log('Profile updated successfully:', response.data);
      toast.success("Profile Updated Successfully");
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error(error.response.data.error);
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required').matches(/^[A-Za-z][A-Za-z'-]* ?([A-Za-z'-]+ ?)*$/, 'Name must contain only letters, spaces, hyphens, and apostrophes').min(2, 'Name must be at least 2 characters').trim(),
    email: Yup.string().email("Invalid email").required("Email is required"),
    mobile: Yup.string().matches(/^[6789][0-9]{9}$/, 'Must be a 10 digit number starting with 6, 7, 8, or 9').required("Mobile is required")
  });

  const handleDateChange = (date) => {
    setDate(date);
    console.log("Date changed:", date);
    const dateString = date;
    const dateObject = new Date(dateString);
    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObject.getDate().toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    setDob(formattedDate);
    setIsDirty(true);
    setOpen(false);
  };

  const handleIconClick = () => {
    setOpen(true);
  };

  return (
    <Box className="editProfile">
      <div className='title'>
        <Typography className='titleName'>EDIT PROFILE</Typography>
      </div>
      {loading ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: '60vh', alignContent: 'center', justifyContent: 'center' }}>
          <CircularProgress />
          <Typography>Fetching your details...</Typography>
        </Box>
      ) : userData ? (
        <Box className='form'>
          <Formik
            initialValues={formValues}
            onSubmit={(values) => handleUpdateProfile(values)}
            validationSchema={validationSchema}
          >
            {(formikProps) => (
              <div className='form-content'>
                  <div className='profile'>
                      <Box className="editImage" sx={{ cursor: 'pointer' }} onClick={() => document.getElementById('profileImageInput').click()}>
                        <input type="file" id="profileImageInput" style={{ display: 'none' }} onChange={handleAddProfile} />                       
                        <img 
                          src={ userData.profileImage ? userData.profileImage : (userData?.gender === 'MALE' ? Images.maleAvatar : (userData?.gender === 'FEMALE' ? Images.femaleAvatar : Images.navProfile)) }
                          // src={userData.profileImage || Images.profileHead} 
                          alt="profilePic" className='profilePic'
                        />
                        <ModeEdit className="editIcon" />
                      </Box>
                      <div className='profileDetails'>
                        <Typography className='profileName'>{userData.name}</Typography>
                        <Typography className='profileDate'>Joined: {formattedDate} </Typography>
                      </div>
                  </div>
                  <TextField
                    label="Name"
                    variant="outlined"
                    name="name"
                    className="fields"
                    fullWidth
                    value={formikProps.values.name}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    error={formikProps.touched.name && Boolean(formikProps.errors.name)}
                    helperText={formikProps.touched.name && formikProps.errors.name}
                  />
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Select Gender"
                    className="fields"
                    fullWidth
                    value={formikProps.values.gender || userData.gender || ''}
                    onChange={(event) => {
                      formikProps.handleChange(event);
                      setIsDirty(true);
                      console.log(event.target.value);
                    }}
                    name="gender"
                    defaultValue="MALE"
                  >
                    <MenuItem value="">Select Gender</MenuItem>
                    {genders.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    label="Mobile"
                    variant="outlined"
                    name="mobile"
                    className="fields"
                    fullWidth
                    value={formikProps.values.mobile}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    error={formikProps.touched.mobile && Boolean(formikProps.errors.mobile)}
                    helperText={formikProps.touched.mobile && formikProps.errors.mobile}
                  />
                  <TextField
                    label="Email"
                    variant="outlined"
                    name="email"
                    className="fields"
                    fullWidth
                    value={formikProps.values.email}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    error={formikProps.touched.email && Boolean(formikProps.errors.email)}
                    helperText={formikProps.touched.email && formikProps.errors.email}
                  />
                  {userData?.dob ? (
                    <TextField
                      label="Birthday"
                      variant="outlined"
                      name="birthday"
                      className="fields"
                      fullWidth
                      value={dob}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setOpen(!open)}>
                              <DateRange />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  ) : (
                    <Typography  onClick={() => setOpen(!open)} style={{ cursor: 'pointer' }}>
                      Add Birthday
                    </Typography>
                  )}
                  {open && (
                    <DatePicker
                      // selected={selectedDate}
                      onChange={handleDateChange}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      maxDate={new Date()}
                      dateFormat="yyyy-MM-dd"
                      inline
                      className="custom-datepicker"
                    />
                  )}
                  <Button
                    onClick={formikProps.handleSubmit}
                    className='saveChangesButton'
                    // disabled={!formikProps.dirty || !isDirty}
                    style={{ backgroundColor: '#09A5F7' }}
                  >
                    Save Changes
                  </Button>
              </div>
            )}
          </Formik>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: '60vh', alignContent: 'center', justifyContent: 'center' }}>
          <Typography>Error fetching user data</Typography>
        </Box>
      )}
    </Box>
  );
};

export default EditProfile;