import React, { useEffect } from 'react';
import { Routes, Route, useNavigate, useParams, useLocation } from 'react-router-dom';
import AuthLayout from './AuthLayout';
import AppLayout from './AppLayout';
import MobileNumber from './screens/Auth/MobileNumber';
import VerifyOtp from './screens/Auth/VerifyOtp';
import AccountDetails from './screens/Auth/AccountDetails';
import CouponScreen from './screens/App/CouponScreen';
import LocationScreen from './screens/App/LocationScreen';
import ProductsListing from './screens/App/ProductsListing';
import ReviewScreen from './screens/App/ReviewScreen';
import ProfileScreen from './screens/App/ProfileScreen';
import HomeScreen from './screens/App/HomeScreen';
import CartScreen from './screens/App/CartScreen';
import PaymentScreen from './screens/App/PaymentScreen';
import SplashScreen from './screens/Auth/SplashScreen';
import WelcomeScreen from './screens/Auth/WelcomeScreen';
import OrderDetails from './screens/App/OrderDetails';
import { useMediaQuery } from '@mui/material';
import UPIScreen from './screens/App/PaymentScreens/UPIScreen';
import NetBanking from './screens/App/PaymentScreens/NetBanking';
import CardScreen from './screens/App/PaymentScreens/CardScreen';
import CouponsScreen from './screens/App/CouponsScreen';
import OrderHistoryScreen from './screens/App/OrderHistoryScreen';
import EditProfile from './screens/App/ProfileScreens/EditProfile';
import AdvertiseScreen from './screens/App/AdvertiseScreen';
import WishListScreen from './screens/App/WishListScreen';
import MembershipScreen from './screens/App/MembershipScreen';
import DeliveryScreen from './screens/App/DeliveryScreen';
import SavedAddressesScreen from './screens/App/ProfileScreens/SavedAddressesScreen';
import OrderDetailsDelivery from './screens/App/OrderDetailsDelivery';
import AddAddressMapScreen from './screens/App/DeliveryScreens/AddAddressMapScreen';
import AddAddressScreen from './screens/App/DeliveryScreens/AddAddressScreen';
import DeliveryOutletScreen from './screens/App/DeliveryScreens/DeliveryOutletScreen';
import OfferScreen from './screens/App/OfferScreen';
import PickupOutletScreen from './screens/App/PickupScreens/PickupOutletScreen';
import RewardsScreen from './screens/App/ProfileScreens/RewardsScreen';
import PrivacyPolicy from './screens/App/AppPolicyScreens/PrivacyPolicy';
import FAQs from './screens/App/AppPolicyScreens/FAQs';
import TermsAndCondition from './screens/App/AppPolicyScreens/TermsAndCondition';

const AppRoutes = () => {
  const isMobile = useMediaQuery('(max-width: 959px)');
  const isSmallScreen = useMediaQuery('(max-width: 959px)');

  return (
    <Routes>
      {isSmallScreen ? (
        <Route path='/:referId?' element={<SplashScreen />} />
      ) : (
        <Route path='/' element={<AppLayout />}>
          <Route path='/:referId?' element={<ProductsListing />} />
        </Route>
      )}
      <Route path='/' element={<AuthLayout />}>
        {isMobile ? (
          <>
            <Route path='/mobileNumber' element={<MobileNumber />} />
            <Route path='/verifyOtp' element={<VerifyOtp />} />
            <Route path='/accountDetails' element={<AccountDetails />} />
            <Route path='/welcomeScreen' element={<WelcomeScreen />} />
          </>
        ) : null}
      </Route>
      {/* {isMobile ? (
        <>
          <Route path='/locationScreen' element={<LocationScreen />} />
          <Route path='/deliveryScreen' element={<DeliveryScreen />} />
        </>
      ) : null} */}
      <Route path='/pickupOutletScreen' element={<PickupOutletScreen />} />
      <Route path='/locationScreen' element={<LocationScreen />} />
      <Route path='/deliveryScreen' element={<DeliveryScreen />} />
      <Route path='/deliveryOutletScreen' element={<DeliveryOutletScreen />} />
      <Route path='/' element={<AppLayout />}>
        <Route path='/homeScreen' element={<HomeScreen />} />
        <Route path='/couponScreen' element={<CouponScreen />} />
        <Route path='/productsListing' element={<ProductsListing />} />
        <Route path='/cartScreen' element={<CartScreen />} />
        <Route path='/reviewScreen' element={<ReviewScreen />} />
        <Route path='/profileScreen' element={<ProfileScreen />} />
        <Route path='/orderDetailsDelivery' element={<OrderDetailsDelivery />} />
        <Route path='/orderHistory' element={<OrderHistoryScreen />} />
        <Route path='/rewardScreen' element={<RewardsScreen />} />
        <Route path='/couponsScreen' element={<CouponsScreen />} />
        <Route path='/orderHistoryScreen' element={<OrderHistoryScreen />} />
        <Route path='/wishlistScreen' element={<WishListScreen />} />
        <Route path='/addAddressScreen' element={<AddAddressScreen />} />
        <Route path='/offerScreen' element={<OfferScreen />} />
      </Route>
      <Route path='/paymentScreen' element={<PaymentScreen />} />
      <Route path='/addAddressMapScreen' element={<AddAddressMapScreen />} />
      <Route path='/editProfileScreen' element={<EditProfile />} />
      <Route path='/orderDetails' element={<OrderDetails />} />
      <Route path='/membershipScreen' element={<MembershipScreen />} />
      <Route path='/advertiseScreen' element={<AdvertiseScreen />} />
      <Route path='/upiScreen' element={<UPIScreen />} />
      <Route path='/netBankingScreen' element={<NetBanking />} />
      <Route path='/cardScreen' element={<CardScreen />} />
      <Route path='/privacyPolicy' element={<PrivacyPolicy />} />
      <Route path='/frequentlyAskedQuestions' element={<FAQs />} />
      <Route path='/termsAndConditions' element={<TermsAndCondition />} />
      <Route path='/savedAddressesScreen' element={<SavedAddressesScreen />} />
    </Routes>
  );
};

export default AppRoutes;
