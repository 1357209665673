export const API = {
    BASE_URL: 'https://xpanse.dev.bexcart.com/',
}

export const RAZOR_PAY = {
    // RAZOR_PAY_KEY: 'rzp_test_hP3cc52iCczuoQ',
    RAZOR_PAY_KEY: 'rzp_test_WRSgriztjzHeUI',
}

export const GOOGLE_MAP = {
    GOOGLE_MAP_KEY: 'AIzaSyAkMTmrfVp5O6MFzLdcLACGt1FKCy2PVkY',
}