import { Box, Button, ClickAwayListener, Dialog, DialogContent, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import PickUpCartComponent from '../../components/PickUpCartComponent';
import Images from '../../utils/Images';
import QRCode from 'qrcode.react';
import ReactDOMServer from 'react-dom/server';
import { QRCodeSVG } from 'qrcode.react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Player } from '@lottiefiles/react-lottie-player';
import { useDispatch } from 'react-redux';
import { HomeRounded, InfoOutlined, KeyboardArrowRight, LocationOnOutlined, WorkOffOutlined, WorkOutlineOutlined, WorkRounded } from '@mui/icons-material';
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';
import { API, GOOGLE_MAP } from '../../utils/Api';
import axios from 'axios';

const OrderDetailsDelivery = () => {
    const token = localStorage.getItem("token");
    const [qrCodeSvg, setQrCodeSvg] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const orderDetails = location?.state?.orderDetails;
    const screenLocation = location?.state?.screenLocation;
    const dispatch = useDispatch();
    const [countdown, setCountdown] = useState(30);
    const [showBox, setShowBox] = useState(true); 
    const [orderText, setOrderText] = useState("Placed");
    const mapRef = useRef(null);
    const [currentCoordinates, setCurrentCoordinates] = useState({ lat: parseFloat(orderDetails?.order?.deliveryDetails?.coordinates?.latitude), lng: parseFloat(orderDetails?.order?.deliveryDetails?.coordinates?.longitude) });
    const [outlet, setOutlet] = useState({ lat: parseFloat(orderDetails?.order?.outlet?.coordinates?.latitude), lng: parseFloat(orderDetails?.order?.outlet?.coordinates?.longitude) });
    const [outletMarker, setOutletMarker] = useState(Images.xpanseMapMarker);
    const [orderCancelModal, setOrderCancelModal] = useState(false);
    const [levelUpgradeModal, setLevelUpgradeModal] = useState(false);
    const [allRewards, setAllRewards] = useState([]);
    const [openTaxTooltip, setTaxOpenTooltip] = useState(false);

    useEffect(() => {
        if (orderDetails?.order?.orderStatus === 'CANCELLED') {
            setOrderText('Cancelled');
        }
    }, [orderDetails?.order?.orderStatus]);
    
    useEffect(() => {
        const timerId = setInterval(() => {
            setCountdown(currentCount => {
                if (currentCount <= 1) {
                    clearInterval(timerId);
                    setShowBox(false); 
                    return 0;
                }
                return currentCount - 1;
            });
        }, 1000);
        return () => clearInterval(timerId);
    }, [screenLocation]); 

    useEffect(() => {
        const timer = setTimeout(() => {
            setCurrentCoordinates({ lat: parseFloat(orderDetails.order.deliveryDetails.coordinates.latitude), lng: parseFloat(orderDetails.order.deliveryDetails.coordinates.longitude) });
            setOutlet({ lat: parseFloat(orderDetails.order.outlet.coordinates.latitude), lng: parseFloat(orderDetails.order.outlet.coordinates.longitude) }); 
        }, 1000);
        return () => clearTimeout(timer);
    }, [orderDetails]);

    const handleCancelOrder = async () => {
        try {
            const response = await axios.put(`${API.BASE_URL}order/cancel`, {
                "_id": orderDetails.order._id
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            console.log('Order cancelled:', response.data);
            setShowBox(false);
            setOrderText("Cancelled");
            setOrderCancelModal(true);
        } catch (error) {
            console.error('Failed to cancel order:', error);
        }
    };

    const containerStyle = {
        width: "90%",
        height: "45vh",
        margin: "0 20px 0 20px"
    };

    useEffect(() => {
        // dispatch(orderDetails.order.readyTime);
        console.log(location?.state?.orderDetails.order.readyTime);    
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const fetchCurrentCoordinates = async () => {
            try {
                const response = await axios.get(`${API.BASE_URL}order/tracking/${orderDetails?.order?.deliveryOrderId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setOutlet({ lat: parseFloat(orderDetails.order.outlet.coordinates.latitude), lng: parseFloat(orderDetails.order.outlet.coordinates.longitude)});
                setOutletMarker(Images.xpanseMapMarker);
                console.log('Successfully fetched current delivery coordinates:', response.data.data);
            } catch (error) {
                console.error('Error while fetching current delivery coordinates:', error);
            }
        };
        fetchCurrentCoordinates();
        const intervalId = setInterval(fetchCurrentCoordinates, 30000);
        return () => {
            clearInterval(intervalId);
        };
    }, [orderDetails?.order?.deliveryOrderId, token]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const dayOfWeek = days[date.getUTCDay()];
        const month = months[date.getUTCMonth()];
        const year = date.getUTCFullYear();
        const day = date.getUTCDate();
        return `${dayOfWeek}, ${day}- ${month} - ${year}`;
    };

    const totalSavings = useMemo(() => {
        return orderDetails.orderItems?.reduce((acc, item) => {
            const savingsPerItem = (item?.product?.mrp - item?.product?.offerPrice) * item?.quantity;
            const savingsPerAddons = item?.addons?.reduce((addonAcc, addon) => {
                return addonAcc + ((addon?.mrp - addon?.offerPrice) * (item?.quantity || 0));
            }, 0);
            return acc + savingsPerAddons + savingsPerItem;
        }, 0);
    }, [orderDetails?.orderItems]);

    const totalCouponDiscount = orderDetails.orderItems?.reduce((total, item) => {
        return total + (item?.discount);
    }, 0);

    const totalOfferPrice = useMemo(() => {
        return orderDetails?.orderItems?.reduce((acc, item) => {
            const totalOfferPrice = (item?.product?.offerPrice) * item?.quantity;
            return acc + totalOfferPrice;
        }, 0);
    }, [orderDetails?.orderItems]);

    const totalSizeUpgradeAmount = useMemo(() => {
        return orderDetails.orderItems?.reduce((acc, item) => {
            return acc + (item?.sizeUpgradePayload?.newAddonItemOfferPrice || 0) - (item?.sizeUpgradePayload?.prevAddonItemOfferPrice || 0);
        }, 0);
    }, [orderDetails.orderItems]);

    const totalTaxAmount = orderDetails?.order?.totalTaxAmount;
    const totalSum = totalTaxAmount + totalOfferPrice;
    const formattedSum = totalSum?.toFixed(2)?.replace(/\.?0*$/, '');
    const totalQuantityInOrder = orderDetails?.orderItems?.reduce((total, item) => { return total + item?.quantity; }, 0);
    
    useEffect(() => {
        if (orderDetails && orderDetails.order.deliveryDetails.coordinates && mapRef.current) {
            const bounds = new window.google.maps.LatLngBounds();
            bounds.extend(new window.google.maps.LatLng(currentCoordinates.lat, currentCoordinates.lng));
            bounds.extend(new window.google.maps.LatLng(outlet.lat, outlet.lng));
            mapRef.current.fitBounds(bounds);
        }
    }, [orderDetails, currentCoordinates, outlet]);
    
    const onLoad = map => {
        mapRef.current = map;
    };

    const onUnmount = () => {
        mapRef.current = null;
    };

    const handleTaxTooltipOpen = () => {
        setTaxOpenTooltip(true);
    };

    const handleTaxTooltipClose = () => {
        setTaxOpenTooltip(false);
    };

    const taxTooltipContent = (
        <Box>
            <Typography variant="body2" padding={'2px 0 2px 0'} sx={{ display: 'flex', justifyContent: 'space-between'}} component="p">
                Base Tax: <span>₹{orderDetails?.order?.totalTaxAmount}</span>
            </Typography>
            {orderDetails?.order?.ordersType === 'DELIVERY' && (
                <Typography variant="body2" padding={'2px 0 2px 0'} sx={{ display: 'flex', justifyContent: 'space-between'}} component="p">
                    GST Packing Charges: <span>₹{orderDetails?.order?.GSTPackingCharges}</span>
                </Typography>
            )}
            <Typography variant="body2" padding={'2px 0 2px 0'} sx={{ display: 'flex', justifyContent: 'space-between'}} component="p">
                GST Platform Fees: <span>₹{orderDetails?.order?.GSTPlatformFees}</span>
            </Typography>
            <Typography variant="body2" padding={'2px 0 2px 0'} component="p" sx={{ borderTop: '1px solid #fff', display: 'flex', justifyContent: 'space-between' }}>
                Total Tax: <span>₹ {totalTaxAmount?.toFixed(2)}</span>
            </Typography>
        </Box>
    );

    const calculateTotalPrice = (item) => {
        let totalPrice = item?.product?.offerPrice * item?.quantity;
        item?.addons?.forEach(addon => {
        totalPrice += addon?.offerPrice * item?.quantity;
        });
        return totalPrice?.toFixed(2).replace(/\.?0*$/, '');
    };

    const getAllRewardsInfo = async () => {
        try {
            const response = await axios.get(`${API.BASE_URL}level/getAllRewards`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            console.log('membership rewards', response.data);
            console.log('Level upgraded to', orderDetails?.levelUpgradedTo);
            if (orderDetails?.levelUpgradedTo !== null) {
                setLevelUpgradeModal(true);
                const FilteredReward = response?.data?.data?.allLevelRewards?.filter(item => item?._id === orderDetails?.levelUpgradedTo?.levelId);
                setAllRewards(FilteredReward);
                console.log('uyfghfg hggcvhgg hchgghgg hgchgc ggchg', FilteredReward);
            }
        } catch (error) {
            console.log(error, 'error in order Sucess');
        }
    }

    useEffect(() => {
        if (screenLocation === "PAYMENT") {
            getAllRewardsInfo();
        }
    }, [screenLocation]);

    const totalMrpOrder = useMemo(() => {
        return orderDetails?.orderItems?.reduce((acc, item) => {
            const productMrp = (item?.product?.mrp || 0) * (item?.quantity || 0);
            const addonsMrp = item?.addons?.reduce((addonAcc, addon) => {
                return addonAcc + ((addon?.mrp || 0) * (item?.quantity || 0));
            }, 0);
            return acc + productMrp + addonsMrp;
        }, 0);
    }, [orderDetails?.orderItems]);

    const totalOfferPriceOrder = useMemo(() => {
        return orderDetails?.orderItems?.reduce((acc, item) => {
            const productOfferPrice = (item?.product?.offerPrice || 0) * (item?.quantity || 0);
            const addonsOfferPrice = item?.addons?.reduce((addonAcc, addon) => {
                return addonAcc + ((addon?.offerPrice || 0) * (item?.quantity || 0));
            }, 0);
            return acc + productOfferPrice + addonsOfferPrice;
        }, 0);
    }, [orderDetails?.orderItems]);

    return (
    <LoadScript googleMapsApiKey={GOOGLE_MAP.GOOGLE_MAP_KEY}>
        <Box className="orderDetails">
            <PickUpCartComponent />
            {screenLocation === "PAYMENT" && showBox &&
                <Box sx={{ backgroundColor: '#71BAE6', padding: '20px', color: '#fff', fontSize: '20px', textAlign: 'center', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                    Order to cancel {countdown}s
                    <Button 
                        onClick={handleCancelOrder} 
                        sx={{ color: 'white', backgroundColor: 'red' }}
                    >
                        Cancel Order
                    </Button>
                </Box>
            }
            {orderDetails?.order?.orderStatus !== "DELIVERED" && orderDetails?.order?.orderStatus !== "CANCELLED" && (
                <>
                    <Box sx={{ position: 'absolute', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '19', backgroundColor: '#fff', color: '#186B8C', fontWeight: '600', padding: '8px 20px', borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px', width: '120px' }}>
                        Order Id: #{orderDetails?.order?.order_sequence}{orderDetails?.order?.order_no}
                    </Box>
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                        center={currentCoordinates}
                        options={{ mapTypeControl: false, zoomControl: false, fullscreenControl: false, keyboardShortcuts: false, streetViewControl: false }}
                    >
                        <MarkerF
                            position={currentCoordinates}
                            title="Your Location"
                        />
                        <MarkerF
                            position={outlet}
                            icon={outletMarker}
                            title="Outlet"
                        />
                    </GoogleMap>
                    <Box className="orderDeliveryDetail">
                        <Box sx={{ display: 'flex' }}>
                            <img className="successGifDelivery" src={Images.successTick} alt='successTick' />
                            <Typography className="titleDelivery">Your order has been {orderText} successfully!</Typography>
                        </Box>
                        <Box sx={{ padding: '0 12px 0px 12px'}}>
                            <Box sx={{ display: 'flex' }}>
                                <Typography sx={{ fontWeight: '600' }}>Delivering to :</Typography>
                                {orderDetails?.order?.deliveryDetails.type === 'HOME' && <HomeRounded sx={{ color: '#1E9CED' }} />}
                                {orderDetails?.order?.deliveryDetails.type === 'WORK' && <WorkRounded sx={{ color: '#1E9CED' }} />}
                                {orderDetails?.order?.deliveryDetails.type !== 'HOME' && orderDetails?.order?.deliveryDetails.type !== 'WORK' && <LocationOnOutlined sx={{ color: '#1E9CED' }} />}
                                <Typography sx={{ fontWeight: '600' }}>{orderDetails?.order?.deliveryDetails.type}</Typography>
                            </Box>
                            <Typography>{orderDetails?.order?.deliveryDetails.recieverName} {orderDetails?.order?.deliveryDetails.addressLine1}, {orderDetails?.order?.deliveryDetails.addressLine2}, {orderDetails?.order?.deliveryDetails.city}, {orderDetails?.order?.deliveryDetails.state}, {orderDetails?.order?.deliveryDetails.pincode}</Typography>
                        </Box>
                        <img className="couponBorderInverted" src={Images.couponBorderInverted} />
                    </Box>
                </>
            )}
            <Box className="orderFinalSummary">
                <img className="couponBorder" src={Images.couponBorder} />
                <Typography className="titleSecond">Item Summary</Typography>
                <Box sx={{ padding: '0 20px 0 20px'}}>
                    {orderDetails?.orderItems?.map((item, index) => (
                        <Grid key={item?._id} container className="item" sx={{ borderBottom: index === orderDetails.orderItems.length - 1 ? 'none' : '1px solid #C5C5C5' }}>
                            <Grid item className="detail-grid" xs={6}>
                                <Typography className="item-name">{item?.product?.name}</Typography>
                                {item?.addons?.length > 0 && (
                                    <>
                                        {item.addons.map((addon, index) => (
                                            <Typography key={index} className="item-attribute">
                                                {addon.selectedValue}{index === item.addons.length - 1 ? '.' : ','}
                                            </Typography>
                                        ))}
                                    </>
                                )}
                            </Grid>
                            <Grid item className="detail-grid" xs={3} sx={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
                                <Typography className="item-qty">Qty - {item?.quantity}</Typography>
                            </Grid>
                            <Grid item className="detail-grid" xs={3} sx={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
                                <Typography className="item-cost">₹{calculateTotalPrice(item)}</Typography>
                            </Grid>
                        </Grid>
                    ))}
                </Box>
            </Box>
            <Box className="orderSummarySecondBox">
                <Box className="orderSummaryTable">
                    <Typography className="head">Order Summary</Typography>
                    <Box className="row">
                        <Typography className="itemHead">Item(s)</Typography>
                        <Typography className="itemValue">{totalQuantityInOrder}</Typography>
                    </Box>
                    <Box className="row">
                        <Typography className="itemHead">Order Total</Typography>
                        <Typography className="itemValue"><span style={{ fontSize: '14px', textDecoration: 'line-through', color: 'gray' }}>₹{totalMrpOrder}</span> ₹{totalOfferPriceOrder}</Typography>
                    </Box>  
                    <Box className="row">
                        <Typography className="itemHead" sx={{ display: 'flex', alignItems: 'center'}}>Tax
                            <ClickAwayListener ClickAwayListener onClickAway={handleTaxTooltipClose}>
                            <Tooltip
                                PopperProps={{ disablePortal: true, }}
                                onClose={handleTaxTooltipClose}
                                open={openTaxTooltip}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={taxTooltipContent}
                            >
                                <IconButton onClick={handleTaxTooltipOpen} size="small" sx={{ padding: 0, marginLeft: '5px' }}>
                                    <InfoOutlined sx={{ fontSize: '12px' }} />
                                </IconButton>
                            </Tooltip>
                            </ClickAwayListener>
                        </Typography>
                        <Typography className="itemValue">₹ {totalTaxAmount?.toFixed(2)}</Typography>
                    </Box>
                    {totalCouponDiscount > 0 && (
                        <Box className="row">
                            <Typography className="itemHead">Coupon Discount</Typography>
                            <Typography className="itemValue" sx={{ color: 'green' }}>- ₹{totalCouponDiscount?.toFixed(2).replace(/\.?0*$/, '')}</Typography>
                        </Box>
                    )}
                    {/* {totalSizeUpgradeAmount > 0 &&
                        <Box className="row">
                            <Typography className="itemHead">Coupon Discount</Typography>
                            <Typography className="itemValue" sx={{ color: 'green' }}>- ₹{totalSizeUpgradeAmount?.toFixed(2)}</Typography>
                        </Box>
                    } */}
                    <Box className="row">
                        <Typography className="itemHead">Platform Fees</Typography>
                        <Typography className="itemValue">{orderDetails?.order?.platformFees?.toFixed(2) === 0 ? "Free" : `₹ ${orderDetails?.order?.platformFees?.toFixed(2)}`}</Typography>
                    </Box>
                    {orderDetails?.order?.ordersType === 'DELIVERY' && (
                        <>
                        <Box className="row">
                            <Typography className="itemHead">Packaging Charges</Typography>
                            <Typography className="itemValue">{orderDetails?.order?.packagingCharges?.toFixed(2) === 0 ? "Free" : `₹ ${orderDetails?.order?.packagingCharges?.toFixed(2)}`}</Typography>
                        </Box>
                        <Box className="row">
                            <Typography className="itemHead">Shipping</Typography>
                            <Typography className="itemValue">{orderDetails?.order?.deliveryCharges?.toFixed(2) === 0 ? "Free" : `₹ ${orderDetails?.order?.deliveryCharges?.toFixed(2)}`}</Typography>
                        </Box>
                        </>
                    )}
                    {orderDetails?.order?.coupon && totalSizeUpgradeAmount <= 0 && ( <Typography className="itemHead" sx={{ paddingBottom: '4px', color: '#1E9CED'}}>( {orderDetails?.order?.coupon?.couponCode} )</Typography>)}
                    <Box className="row" sx={{ borderTop: '1px dashed #707070', borderBottom: '1px solid #707070'}}>
                        <Typography className="itemHead" sx={{ fontWeight: '600'}}>Total Payable</Typography>
                        <Typography className="itemValue" sx={{ fontWeight: '600'}}>₹ {orderDetails?.order?.grandTotal.toFixed(2)}</Typography>
                    </Box>
                </Box>
                <Box className="savedBox">
                    <Typography>You Saved</Typography>
                    <Typography>₹ {((parseFloat(totalSavings || 0) + parseFloat(orderDetails?.order?.totalDiscount || 0)).toFixed(2))}</Typography>
                    {/* <Typography>₹ {orderDetails?.order?.totalDiscount}</Typography> */}
                </Box>
                <Box className="paymentBox">
                    <Typography className="head">Payment Details</Typography>
                    <Box className="payDetails">
                        <Typography>Payment mode: {orderDetails?.order?.paymentMode}</Typography>
                        <Typography>Date: {formatDate(orderDetails?.order?.createdAt)}</Typography>
                    </Box>
                    <Typography onClick={() => navigate('/productsListing')} className="button">Continue Shopping<KeyboardArrowRight /></Typography>
                </Box>
            </Box>
        </Box>
        <Dialog open={orderCancelModal} onClose={() => { setOrderCancelModal(false); navigate('/productsListing'); }} sx={{ '& .MuiPaper-root': {  borderRadius: '16px', overflow: 'hidden', },}}>
            <Box className="addressModal">
            <Grid container className="address-container" sx={{ display: 'flex', justifyContent: 'center'}}>
                <img src={Images.successTick} />
                <h2>Your order was cancelled</h2>
                <Button onClick={() => { setOrderCancelModal(false); navigate('/productsListing'); }}>Continue Shopping</Button>
            </Grid>
            </Box>
        </Dialog>
        <Dialog open={levelUpgradeModal} onClose={() => { setLevelUpgradeModal(false); }} sx={{ '& .MuiPaper-root': {  borderRadius: '16px', overflow: 'hidden', },}}>
            <DialogContent sx={{ bgcolor: '#fff', overflow: 'auto', borderRadius: 2 }}>
                <div style={{ textAlign: 'center', padding: 2 }}>
                    <img src={Images.successTick} />
                    {allRewards?.length > 0 && (
                    <Typography variant="h6" style={{ fontWeight: 'bold', color: 'primary.contrastText' }}>
                        You have Reached {allRewards[0].title}
                    </Typography>
                    )}
                </div>
                {allRewards?.map((rewardsValues, index) => (
                    <div key={index} style={{ marginTop: 2 }}>
                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                        {rewardsValues.title} Benefits
                    </Typography>
                    {rewardsValues.levelRewards?.map((level, index) => (
                        <div key={index} style={{ display: 'flex', flexDirection: 'row', marginTop: 1 }}>
                            <Typography variant="body2" style={{ marginRight: 1 }}>*</Typography>
                            <div>
                                <Typography variant="body2">{level.title}</Typography>
                                <Typography variant="body2" style={{ color: 'grey.500' }}>
                                    {level.description}
                                </Typography>
                            </div>
                        </div>
                    ))}
                    </div>
                ))}
                <Button onClick={() => { setLevelUpgradeModal(false); }}>Close</Button>
            </DialogContent>
        </Dialog>
    </LoadScript>
  )
}

export default OrderDetailsDelivery;
