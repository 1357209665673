import React from 'react';
import { Box, Typography } from '@mui/material';

function TermsAndCondition() {
    return (
        <div className='termsAndConditionScreen'>
            <Typography className='termsTitle'>TERMS AND CONDITIONS</Typography>
            <Box className="termsContainer">
                    {/* <Typography className='agreement'>Agreement</Typography>
                    <Typography className='termServices'>Terms of Services</Typography> */}
                <Typography className='lastUpdated'>Last Updated on 5/12/2022</Typography>
                <div>
                    <Typography className='termsHeading'>Clause 1</Typography>
                    <Typography className='termsDesc'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra condimentum eget purus in. Consectetur eget id morbi amet amet, in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse aenean leo pharetra in sit semper et. Amet quam placerat sem.</Typography>
                    <Typography className='termsHeading'>Clause 2</Typography>
                    <Typography className='termsDesc'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra condimentum eget purus in. Consectetur eget id morbi amet amet, in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse aenean leo pharetra in sit semper et. Amet quam placerat sem.</Typography>
                    <Typography className='termsDesc'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra condimentum eget purus in. Consectetur eget id morbi amet amet, in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse aenean leo pharetra in sit semper et. Amet quam placerat sem.</Typography>
                    <Typography className='termsHeading'>Clause 2</Typography>
                    <Typography className='termsDesc'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra condimentum eget purus in. Consectetur eget id morbi amet amet, in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse aenean leo pharetra in sit semper et. Amet quam placerat sem.</Typography>
                    <Typography className='termsDesc'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra condimentum eget purus in. Consectetur eget id morbi amet amet, in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse aenean leo pharetra in sit semper et. Amet quam placerat sem.</Typography>
                    <Typography className='termsHeading'>Clause 2</Typography>
                    <Typography className='termsDesc'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra condimentum eget purus in. Consectetur eget id morbi amet amet, in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse aenean leo pharetra in sit semper et. Amet quam placerat sem.</Typography>
                    <Typography className='termsDesc'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra condimentum eget purus in. Consectetur eget id morbi amet amet, in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse aenean leo pharetra in sit semper et. Amet quam placerat sem.</Typography>
                    <Typography className='termsHeading'>Clause 2</Typography>
                    <Typography className='termsDesc'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra condimentum eget purus in. Consectetur eget id morbi amet amet, in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse aenean leo pharetra in sit semper et. Amet quam placerat sem.</Typography>
                    <Typography className='termsDesc'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra condimentum eget purus in. Consectetur eget id morbi amet amet, in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse aenean leo pharetra in sit semper et. Amet quam placerat sem.</Typography>
                    <Typography className='termsHeading'>Clause 2</Typography>
                    <Typography className='termsDesc'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra condimentum eget purus in. Consectetur eget id morbi amet amet, in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse aenean leo pharetra in sit semper et. Amet quam placerat sem.</Typography>
                    <Typography className='termsDesc'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra condimentum eget purus in. Consectetur eget id morbi amet amet, in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse aenean leo pharetra in sit semper et. Amet quam placerat sem.</Typography>
                </div>
            </Box>
        </div>
    )
}

export default TermsAndCondition;