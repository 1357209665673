import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import StoreIcon from '@mui/icons-material/Store';
import { KeyboardArrowDown } from '@mui/icons-material';
import Images from '../utils/Images';
import { useNavigate } from 'react-router-dom';

const StoreHeader = () => {
  const [store, setStore] = useState([]);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setStore(JSON.parse(localStorage.getItem('selectedStoreData')));
    // const userDataLogin = JSON.parse(localStorage.getItem('userDataLogin'));
    setUserData(JSON.parse(localStorage.getItem('userDataLogin')));
    console.log('Store data', store);
  }, []);

  const handleProfileClick = () => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/profileScreen');
    } else {
      navigate('/mobileNumber');
    }
  };

  return (
    <Box className="storeHeader">
        <Box onClick={() => navigate('/advertiseScreen')}>
            <Box sx={{ display: 'flex' }}>
                <StoreIcon className='storeIcon' />
                <Typography>{store?.name}</Typography>
                <KeyboardArrowDown />
            </Box>
            <Typography className="storeAddress2">{store?.address}</Typography>
        </Box>
        <Box>
            <img onClick={handleProfileClick} className='profileImage' src={ userData?.profileImage || Images.profileHead } alt="" />
        </Box>
    </Box>
  )
}

export default StoreHeader;
