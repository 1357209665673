import { Box, Typography, Modal, useMediaQuery, Button, Grid, Dialog, DialogContent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { API } from '../utils/Api';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import CouponsScreen from '../screens/App/CouponsScreen';
import toast from 'react-hot-toast';
import { Player } from '@lottiefiles/react-lottie-player';
import { CelebrationOutlined, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import Images from '../utils/Images';

const CouponsComponent = ({ cartAmount, couponData, refreshTrigger, setRefreshTrigger, refreshCartData, couponDiscount, totalCouponDiscount, totalSizeUpgradeAmount }) => {
  const [couponModal, setCouponModal] = useState(false);
  const [joiningCoupons, setJoiningCoupons] = useState([]);
  const [referralCoupon, setReferralCoupon] = useState([]);
  const [validCoupons, setValidCoupons] = useState([]);
  const [birthdayCoupons, setBirthdayCoupons] = useState([]);
  const [applicableCoupons, setApplicableCoupons] = useState([]);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const [playConfetti, setPlayConfetti] = useState(false);
  const [outletData, setOutletData] = useState(JSON.parse(localStorage.getItem('selectedStoreData')));
  const [couponSavedModal, setCouponSaved] = useState(false);
  
  const handleOpenCouponModal = () => {
    setCouponModal(true);
    fetchCoupons();
  };

  const handleCloseCouponModal = () => {
    setCouponModal(false);
  };

  const navigateToCouponScreen = () => {
    navigate('/couponsScreen', { state: { cartAmount: cartAmount } });
  };

  const applyCoupon = async (couponId) => {
    try {
      const response = await axios.post(`${API.BASE_URL}coupons/applyCoupon`, { couponId }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Coupon applied successfully:', response.data);
      // setRefreshTrigger(!refreshTrigger);
      setPlayConfetti(true);
      setCouponModal(false);
      toast.success("Coupon applied successfully!!!");
      setCouponSaved(true);
      refreshCartData();
      // setTimeout(() => {
      //   setPlayConfetti(false);
      // }, 2000);
    } catch (error) {
      console.error('Error applying coupon:', error);
      toast.error(error.response.data.error);
    }
  };

  const removeCoupon = async (couponId) => {
    try {
      const response = await axios.post(`${API.BASE_URL}coupons/removeCoupon`, { couponId }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Coupon removed successfully:', response.data);
      toast.success("Coupon removed successfully!!!");
      // setRefreshTrigger(!refreshTrigger);
      refreshCartData();
    } catch (error) {
      console.error('Error removing coupon:', error);
      toast.error(error.response.data.error);
    }
  };

  const fetchCoupons = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}coupons/getUserApplicableCoupons/${outletData._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { birthdayCoupon, joiningCoupon, validCoupons, applicableCoupons, referralCoupon } = response.data.data.totalCoupons;
      setBirthdayCoupons(birthdayCoupon);
      setJoiningCoupons(joiningCoupon);
      setReferralCoupon(referralCoupon);
      setValidCoupons(validCoupons);
      setApplicableCoupons(applicableCoupons);
      // console.log(applicableCoupons,'iausdgfiuasgbdfiubhasdfiub');
    } catch (error) {
      console.error('Error fetching coupons:', error);
    }
  };

  useEffect(() => {
    fetchCoupons();
  }, [refreshTrigger]);
  
  return (
    <>
      {playConfetti && (
        <Player
          autoplay
          loop={false}
          src="https://lottie.host/aec009ac-9eae-49c8-a521-5462a46f787b/dhJlEWsRLA.json"
          className="confetti"
          style={{
            position: 'fixed',
          }}
        />
      )}
      <Box className="couponBox">
        <Typography className="title">Offers & Benefits</Typography>
        {couponData ? (
          <Box className="coupon">
            <Box>
              <Typography className="code">{couponData?.couponCode} - APPLIED!</Typography>
              {/* <Typography className="saved">₹{couponData?.couponDiscount} <span style={{ color: '#AEAEAE' }}> Saved</span></Typography> */}
              {/* {couponData?.discountPercent && !couponData?.discountAmount && (
                <Typography className="saved">{couponData?.discountPercent}% <span style={{ color: '#AEAEAE' }}> Saved</span></Typography>
              )}
              {couponData?.discountAmount && (
                <Typography className="saved">₹{couponData?.discountAmount} <span style={{ color: '#AEAEAE' }}> Saved</span></Typography>
              )}
              {couponData?.sizeUpgradeAddon && (
                <Typography className="saved">A free size upgrade on your drink!!!</Typography>
              )}
              {couponData?.isBogoCoupon && (
                <Typography className="saved">BOGO Applied!!!</Typography>
              )} */}
              {totalCouponDiscount > 0 && (
                <Typography className="saved">₹{totalCouponDiscount?.toFixed(2)}<span style={{ color: '#AEAEAE' }}> Saved</span></Typography>
              )}
              {totalSizeUpgradeAmount > 0 &&
                <Typography className="saved">₹{totalSizeUpgradeAmount?.toFixed(2)}<span style={{ color: '#AEAEAE' }}> Saved</span></Typography>
              }
            </Box>
            <Typography className="remove" sx={{ cursor: 'pointer' }} onClick={() => removeCoupon(couponData._id)}>Remove</Typography>
          </Box>
        ) : (
          <Box className="couponAdd" sx={{ cursor: 'pointer' }} onClick={isMdUp ? handleOpenCouponModal : () => navigateToCouponScreen()}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', width: '100%' }}>
              <Typography className="code">Apply new coupon</Typography>
              <KeyboardArrowRight className="code" />
            </Box>
          </Box>
        )}
        { couponData && 
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Typography className="bestApplied">Best Coupon Applied!!</Typography>
            <Typography sx={{ fontSize: '12px', cursor: 'pointer'}} onClick={isMdUp ? handleOpenCouponModal : () => navigateToCouponScreen()}>Show all coupons</Typography>
          </Box>
        }
      </Box>
      {isMdUp ? (
        <Modal open={couponModal} onClose={handleCloseCouponModal}>
          <Box className="couponModal" sx={{outline: 'none'}}>
            <Box className="couponsScreen">
              <Box className="couponContainer">
                {birthdayCoupons?.length > 0 && (
                  <>
                    <Typography className='bestTitle'>Here's your birthday gift from us <CelebrationOutlined /></Typography>
                    {birthdayCoupons?.map(coupon => (
                      <Box className="coupon birthdayCoupon">
                        <Grid container>
                          <Grid item xs={10} className='couponDetailSection'>
                            <div className='couponDetail'>
                              <Typography className='couponName'>{coupon.couponCode}</Typography>
                              {coupon?.discountPercent && !coupon?.discountAmount && (
                                <Typography className='couponOff'>Save {coupon?.discountPercent}% with this coupon</Typography>
                              )}
                              {coupon?.discountAmount && (
                                <Typography className='couponOff'>Save ₹{coupon?.discountAmount} with this coupon</Typography>
                              )}
                              {coupon?.sizeUpgradeAddon && (
                                <Typography className='couponOff'>A free size upgrade on drinks!!!</Typography>
                              )}
                              {coupon?.isBogoCoupon && (
                                <Typography className='couponOff'>Buy one get one free coupon!!!</Typography>
                              )}
                              <Typography className='couponDesc'>{coupon.description}</Typography>
                              <Box className="validAndButton" sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                                {coupon.validityEndDate ? (
                                  <Typography className="validity" sx={{ marginRight: 'auto' }}>
                                    Valid till: {new Date(coupon.validityEndDate).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' }).replace(/\//g, '-')}
                                  </Typography>
                                ) : (
                                  <span style={{ flex: 1 }}></span>
                                )}
                                <Button className='applyButton' onClick={() => applyCoupon(coupon._id)}>Apply</Button>
                              </Box>
                            </div>
                          </Grid>
                          <Grid item xs={2} className='couponDiscountSection'>
                            {coupon?.discountPercent && !coupon?.discountAmount && (
                              <Typography className='verticalFlatOff'>{coupon?.discountPercent}% OFF</Typography> 
                            )}
                            {coupon?.discountAmount && (
                              <Typography className='verticalFlatOff'>₹{coupon?.discountAmount} OFF</Typography> 
                            )}
                            {coupon?.sizeUpgradeAddon && (
                              <Typography className='verticalFlatOff'>FREE!!!</Typography>
                            )}
                            {coupon?.isBogoCoupon && (
                              <Typography className='verticalFlatOff'>FREE!!!</Typography>
                            )}
                            {coupon?.discountPrice && (
                              <Typography className='verticalFlatOff'>{`Only ₹${coupon?.discountPrice}` || `FREE!!!`}</Typography>
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    ))}
                  </>
                )}
                {joiningCoupons?.map(coupon => (
                  <Box className="coupon">
                    <Grid container>
                      <Grid item xs={10} className='couponDetailSection'>
                        <div className='couponDetail'>
                          <Typography className='couponName'>{coupon.couponCode}</Typography>
                          {coupon?.discountPercent && !coupon?.discountAmount && (
                            <Typography className='couponOff'>Save {coupon?.discountPercent}% with this coupon</Typography>
                          )}
                          {coupon?.discountAmount && (
                            <Typography className='couponOff'>Save ₹{coupon?.discountAmount} with this coupon</Typography>
                          )}
                          {coupon?.sizeUpgradeAddon && (
                            <Typography className='couponOff'>A free size upgrade on drinks!!!</Typography>
                          )}
                          {coupon?.isBogoCoupon && (
                            <Typography className='couponOff'>Buy one get one free coupon!!!</Typography>
                          )}
                          <Typography className='couponDesc'>{coupon.description}</Typography>
                          <Box className="validAndButton" sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                            {coupon.validityEndDate ? (
                              <Typography className="validity" sx={{ marginRight: 'auto' }}>
                                Valid till: {new Date(coupon.validityEndDate).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' }).replace(/\//g, '-')}
                              </Typography>
                            ) : (
                              <span style={{ flex: 1 }}></span>
                            )}
                            <Button className='applyButton' onClick={() => applyCoupon(coupon._id)}>Apply</Button>
                          </Box>
                        </div>
                      </Grid>
                      <Grid item xs={2} className='couponDiscountSection'>
                        {coupon?.discountPercent && !coupon?.discountAmount && (
                          <Typography className='verticalFlatOff'>{coupon?.discountPercent}% OFF</Typography> 
                        )}
                        {coupon?.discountAmount && (
                          <Typography className='verticalFlatOff'>₹{coupon?.discountAmount} OFF</Typography> 
                        )}
                        {coupon?.sizeUpgradeAddon && (
                          <Typography className='verticalFlatOff'>FREE!!!</Typography>
                        )}
                        {coupon?.isBogoCoupon && (
                          <Typography className='verticalFlatOff'>FREE!!!</Typography>
                        )}
                        {coupon?.discountPrice && (
                          <Typography className='verticalFlatOff'>{`Only ₹${coupon?.discountPrice}` || `FREE!!!`}</Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                ))}
                {referralCoupon?.map(coupon => (
                  <Box className="coupon">
                    <Grid container>
                      <Grid item xs={10} className='couponDetailSection'>
                        <div className='couponDetail'>
                          <Typography className='couponName'>{coupon.couponCode}</Typography>
                          {coupon?.discountPercent && !coupon?.discountAmount && (
                            <Typography className='couponOff'>Save {coupon?.discountPercent}% with this coupon</Typography>
                          )}
                          {coupon?.discountAmount && (
                            <Typography className='couponOff'>Save ₹{coupon?.discountAmount} with this coupon</Typography>
                          )}
                          {coupon?.sizeUpgradeAddon && (
                            <Typography className='couponOff'>A free size upgrade on drinks!!!</Typography>
                          )}
                          {coupon?.isBogoCoupon && (
                            <Typography className='couponOff'>Buy one get one free coupon!!!</Typography>
                          )}
                          <Typography className='couponDesc'>{coupon.description}</Typography>
                          <Box className="validAndButton" sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                            {coupon.validityEndDate ? (
                              <Typography className="validity" sx={{ marginRight: 'auto' }}>
                                Valid till: {new Date(coupon.validityEndDate).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' }).replace(/\//g, '-')}
                              </Typography>
                            ) : (
                              <span style={{ flex: 1 }}></span>
                            )}
                            <Button className='applyButton' onClick={() => applyCoupon(coupon._id)}>Apply</Button>
                          </Box>
                        </div>
                      </Grid>
                      <Grid item xs={2} className='couponDiscountSection'>
                        {coupon?.discountPercent && !coupon?.discountAmount && (
                          <Typography className='verticalFlatOff'>{coupon?.discountPercent}% OFF</Typography> 
                        )}
                        {coupon?.discountAmount && (
                          <Typography className='verticalFlatOff'>₹{coupon?.discountAmount} OFF</Typography> 
                        )}
                        {coupon?.sizeUpgradeAddon && (
                          <Typography className='verticalFlatOff'>FREE!!!</Typography>
                        )}
                        {coupon?.isBogoCoupon && (
                          <Typography className='verticalFlatOff'>FREE!!!</Typography>
                        )}
                        {coupon?.discountPrice && (
                          <Typography className='verticalFlatOff'>{`Only ₹${coupon?.discountPrice}` || `FREE!!!`}</Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                ))}
                {/* <Typography className='bestTitle'>Applicable Coupons</Typography> */}
                {applicableCoupons?.map(coupon => (
                  <Box className="coupon">
                    <Grid container>
                      <Grid item xs={10} className='couponDetailSection'>
                        <div className='couponDetail'>
                          <Typography className='couponName'>{coupon.couponCode}</Typography>
                          {coupon?.discountPercent && !coupon?.discountAmount && (
                            <Typography className='couponOff'>Save {coupon?.discountPercent}% with this coupon</Typography>
                          )}
                          {coupon?.discountAmount && (
                            <Typography className='couponOff'>Save ₹{coupon?.discountAmount} with this coupon</Typography>
                          )}
                          {coupon?.sizeUpgradeAddon && (
                            <Typography className='couponOff'>A free size upgrade on drinks!!!</Typography>
                          )}
                          {coupon?.isBogoCoupon && (
                            <Typography className='couponOff'>Buy one get one free coupon!!!</Typography>
                          )}
                          <Typography className='couponDesc'>{coupon.description}</Typography>
                          <Box className="validAndButton" sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                            {coupon.validityEndDate ? (
                              <Typography className="validity" sx={{ marginRight: 'auto' }}>
                                Valid till: {new Date(coupon.validityEndDate).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' }).replace(/\//g, '-')}
                              </Typography>
                            ) : (
                              <span style={{ flex: 1 }}></span>
                            )}
                            <Button className='applyButton' onClick={() => applyCoupon(coupon._id)}>Apply</Button>
                          </Box>
                        </div>
                      </Grid>
                      <Grid item xs={2} className='couponDiscountSection'>
                        {coupon?.discountPercent && !coupon?.discountAmount && (
                          <Typography className='verticalFlatOff'>{coupon?.discountPercent}% OFF</Typography> 
                        )}
                        {coupon?.discountAmount && (
                          <Typography className='verticalFlatOff'>₹{coupon?.discountAmount} OFF</Typography> 
                        )}
                        {coupon?.sizeUpgradeAddon && (
                          <Typography className='verticalFlatOff'>FREE!!!</Typography>
                        )}
                        {coupon?.isBogoCoupon && (
                          <Typography className='verticalFlatOff'>FREE!!!</Typography>
                        )}
                        {coupon?.discountPrice && (
                          <Typography className='verticalFlatOff'>{`Only ₹${coupon?.discountPrice}` || `FREE!!!`}</Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                ))}
                {validCoupons?.length > 0 && (
                  <>
                    <Typography className='bestTitle'>Other available offers</Typography>
                    {validCoupons?.map(coupon => (
                      <Box className="coupon notApplicable">
                        <Grid container>
                          <Grid item xs={10} className='couponDetailSection'>
                            <div className='couponDetail'>
                              <Typography className='couponTag'>{coupon.couponMessage}</Typography>
                              <Typography className='couponName'>{coupon.couponCode}</Typography>
                              {coupon?.discountPercent && !coupon?.discountAmount && (
                                <Typography className='couponOff'>Save {coupon?.discountPercent}% with this coupon</Typography>
                              )}
                              {coupon?.discountAmount && (
                                <Typography className='couponOff'>Save ₹{coupon?.discountAmount} with this coupon</Typography>
                              )}
                              {coupon?.sizeUpgradeAddon && (
                                <Typography className='couponOff'>A free size upgrade on drinks!!!</Typography>
                              )}
                              {coupon?.isBogoCoupon && (
                                <Typography className='couponOff'>Buy one get one free coupon!!!</Typography>
                              )}
                              <Typography className='couponDesc'>{coupon.description}</Typography>
                              <Box className="validAndButton">
                                {coupon.validityEndDate && (
                                  <Typography className="validity">
                                    Valid till: {new Date(coupon.validityEndDate).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' }).replace(/\//g, '-')}
                                  </Typography>
                                )}
                              </Box>
                            </div>
                          </Grid>
                          <Grid item xs={2} className='couponDiscountSection'>
                            {coupon?.discountPercent && !coupon?.discountAmount && (
                              <Typography className='verticalFlatOff'>{coupon?.discountPercent}% OFF</Typography> 
                            )}
                            {coupon?.discountAmount && (
                              <Typography className='verticalFlatOff'>₹{coupon?.discountAmount} OFF</Typography> 
                            )}
                            {coupon?.sizeUpgradeAddon && (
                              <Typography className='verticalFlatOff'>FREE!!!</Typography>
                            )}
                            {coupon?.isBogoCoupon && (
                              <Typography className='verticalFlatOff'>FREE!!!</Typography>
                            )} 
                            {coupon?.discountPrice && (
                              <Typography className='verticalFlatOff'>{`Only ₹${coupon?.discountPrice}` || `FREE!!!`}</Typography>
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    ))}
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Modal>
      ) : null}
      <Dialog open={couponSavedModal} onClose={() => { setCouponSaved(false); setPlayConfetti(false) }} sx={{ '& .MuiPaper-root': {  borderRadius: '16px', overflow: 'hidden', zIndex: 1000 }}}>
        <DialogContent sx={{ bgcolor: '#fff', overflow: 'auto', borderRadius: 2 }}>
          <div style={{ textAlign: 'center', padding: 2 }}>
            <img src={Images.successTick} />
          </div>
          {totalCouponDiscount > 0 && (
            <Box className="row">
              <Typography className="itemHead">Coupon Discount</Typography>
              <Typography className="itemValue" sx={{ color: 'green' }}>- ₹{totalCouponDiscount?.toFixed(2)}</Typography>
            </Box>
          )}
          {totalSizeUpgradeAmount > 0 &&
            <Box className="row">
              <Typography className="itemHead">Coupon Discount</Typography>
              <Typography className="itemValue" sx={{ color: 'green' }}>- ₹{totalSizeUpgradeAmount?.toFixed(2)}</Typography>
            </Box>
          }
          <Button onClick={() => { setCouponSaved(false); setPlayConfetti(false) }}>Close</Button>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default CouponsComponent;
