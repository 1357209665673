import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { KeyboardArrowLeftOutlined } from '@mui/icons-material';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { useNavigate } from 'react-router-dom';
import Images from '../utils/Images';
import { useDispatch, useSelector } from 'react-redux';

const PickUpCartComponent = () => {
  const navigate = useNavigate();
  const [store, setStore] = useState([]);
  const [userData, setUserData] = useState(null);
  const activePickTime = useSelector(state => state?.time?.activePickTime);
  const [selectedOrderType, setSelectedOrderType] = useState(localStorage.getItem('selectedOrderType'));
  
  useEffect(() => {
    setStore(JSON.parse(localStorage.getItem('selectedStoreData')));
    const userDataLogin = JSON.parse(localStorage.getItem('userDataLogin'));
    setUserData(userDataLogin);
    console.log('Store data', store);
  }, []);

  const handleGoBack = () => {
    navigate('/productsListing');
  };

  return (
    <Box className="pickUpCartComponent">
        <Box 
          className="address" 
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} 
          onClick={handleGoBack}
        >
            <KeyboardArrowLeftOutlined />
            <Box>
                <Typography> <FmdGoodIcon color='#1DA1F2' className='addressIcon' /> {selectedOrderType === 'PICKUP' ? 'Pickup' : 'Delivery'}</Typography>
                <Typography className="storeAddress2">{store?.city}</Typography>
            </Box>
        </Box>
        <Box className="secondBox">
          {activePickTime && (
            <>
              <Typography>{activePickTime}</Typography>
              <Typography>Ready Time</Typography>
            </>
          )}
        </Box>
    </Box>
  )
}

export default PickUpCartComponent;