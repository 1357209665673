import { Box, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { API } from '../utils/Api';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { setData } from '../store/Cart/cartSlice';

const OrderAgain = () => {
    const token = localStorage.getItem("token");
    const [orderHistory, setOrderHistory] = useState([]);
    const deliveryType = localStorage.getItem("selectedOrderType");
    const outlet = localStorage.getItem("selectedStoreData");
    const outletObject = JSON.parse(outlet);
    const outletId = outletObject?._id;
    const [loadingOrders, setLoadingOrders] = useState({});
    const dispatch = useDispatch();
    const [outletData, setOutletData] = useState(JSON.parse(localStorage.getItem('selectedStoreData')));

    const fetchUserCartData = async () => {
      try {
        const response = await axios.get(`${API.BASE_URL}cart/userCart/${outletData._id}/${deliveryType}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        dispatch(setData(response?.data));
        console.log('Cart Screen Data', response?.data);
      } catch (error) {
        dispatch(setData([]));
        console.log('Cart Screen Error', error?.response?.data);
      }
    };

    const getOrders = async () => {
        try {
            const response = await axios.get(`${API.BASE_URL}order/getConsumerOrders/${outletData._id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setOrderHistory(response.data.data.orders);
            // console.log("Orders history fetched successfully", response.data.data.orders);
        } catch (error) {
            console.error('Error while fetching orders history data:', error);
        }
    };

    useEffect(() => {
        getOrders();
        setOutletData(JSON.parse(localStorage.getItem('selectedStoreData')));
    }, []);

    const handleAddToCart = async (orderId, orderItems) => {
        try {
            setLoadingOrders(prevState => ({
                ...prevState,
                [orderId]: true
            }));
    
            const cartItems = orderItems.map(item => {
                const itemToAdd = {
                    product: item.product._id,
                    quantity: item.quantity
                };
                if (item.addons && item.addons.length > 0) {
                    itemToAdd.addons = item.addons.map(addon => ({
                        _id: addon._id,
                        selectedValue: addon.selectedValue,
                        addonValueId: addon.selectedValueId
                    }));
                }
                return itemToAdd;
            });
    
            const requestBody = {
                ordersType: deliveryType,
                outlet: outletId,
                cartItems: cartItems,
            };
    
            console.log('Sending this data to API:', JSON.stringify(requestBody, null, 2)); // This will log the full request
    
            const response = await axios.post(`${API.BASE_URL}cart`, requestBody, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
    
            setLoadingOrders(prevState => ({
                ...prevState,
                [orderId]: false
            }));
            toast.success('Product(s) with addons added to cart!!!');
            fetchUserCartData(); // Refresh cart data after adding items
            console.log('API response:', response.data);
        } catch (error) {
            setLoadingOrders(prevState => ({
                ...prevState,
                [orderId]: false
            }));
            console.error('Failed to add items with addons:', error.response ? error.response.data : error.message);
            toast.error('Failed to add products with addons.');
        }
    };

    return (
        <>
            {orderHistory.length > 0 && (
                <Box className="orderAgain">
                    <Typography className="title">Order Again</Typography>
                    <Box className="orderContainer">
                        {orderHistory.map((order) => (
                            <Box key={order.order._id} className="orderBox">
                                <Box className="orderOff">
                                    {Math.round(((order.orderItems[0].product.mrp - order.orderItems[0].product.offerPrice) / order.orderItems[0].product.mrp) * 100)}% OFF
                                </Box>
                                {order.orderItems.length <= 2 ? (
                                    order.orderItems.map((item, index) => (
                                        <Box key={index} className="orderItem">
                                            <Box className="orderNumber">{item.quantity}x</Box>
                                            <Typography className="orderName">{item.product.name}</Typography>
                                        </Box>
                                    ))
                                ) : (
                                    <>
                                        <Box className="orderItem">
                                            <Box className="orderNumber">{order.orderItems[0].quantity}x</Box>
                                            <Typography className="orderName">{order.orderItems[0].product.name}</Typography>
                                        </Box>
                                        <Box className="orderItem">
                                            <Box className="orderNumber">{order.orderItems.length - 1}+</Box>
                                            <Typography className="orderName">More Items</Typography>
                                        </Box>
                                    </>
                                )}
                                <Box className="orderItemSummary">
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography className="orderSummaryItems">{order.orderItems.reduce((total, item) => total + item.quantity, 0)} items</Typography>
                                        <Typography className="orderSummaryCost">₹{order.order.grandTotal?.toFixed(2).replace(/\.?0*$/, '')}</Typography>
                                    </Box>
                                    <Typography className="orderAgainTitle" onClick={() => handleAddToCart(order.order._id, order.orderItems)}>
                                        {loadingOrders[order.order._id] ? 'Ordering...' : 'Order Again'}
                                    </Typography>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Box>
            )}
        </>
    );
};

export default OrderAgain;
